import React, { useMemo, useEffect } from 'react';
import Select from 'react-select';
import _map from 'lodash/map';
import _find from 'lodash/find'
import S from 'StyledRegisterWizardForm.js';
import Z from 'StyledRegisterFormByMyId.js';
import { translation, redirect, pushCustomEventToGoogleAnalytics } from 'helpers/utilsHelper.js';
import { SubmissionError, Field, formValues, reduxForm } from 'redux-form';
import { isValueUnique } from 'authActions.js';
import classNames from 'classnames';
import CheckIcon from 'green-tick.svg';
import RegisterByMyIdAttributes from 'registerByMyIdAttributes.enum.js';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.REGISTER_BY_MY_ID_STEP1);
    return errors;
};

const renderField = ({ input, label, focusLabel, required, type, disabled, openTooltip, preventAutoComplete, meta: { touched, error, warning, valid, asyncValidating, submitFailed } }) => {

    const isDisabled = (typeof disabled != 'undefined') ? disabled : false;
    const inputClass = classNames({
        'async-validating': asyncValidating,
        'has-error': error,
        'is-valid-check-data': ( !submitFailed && valid),
        'disabled-input': disabled,
        'has-prefix-mobile': (input.name == 'mobile')
    });


    return (
        <S.Row className={input.name == 'documentNumber' || input.name == 'number' ? 'left':
                         (input.name == 'documentExpirationDate' || input.name == 'postalCode' ? 'right': 
                         (input.name == 'identifier' ? 'hidden-field' : ''))}>
            <S.InputWrapper>

                <S.Input {...input}
                    type={type}
                    required="required"
                    className={inputClass}
                    autoComplete={preventAutoComplete}
                    disabled={isDisabled}
                />

                <S.Label>
                    {(label && !valid && <span className="bottomLabel" dangerouslySetInnerHTML={{ __html: label }} ></span>)}
                    {(focusLabel && <span className="topLabel" dangerouslySetInnerHTML={{ __html: focusLabel }} ></span>)}
                </S.Label>

                <Z.InputIconCheckedContainer>
                    {(valid && <Z.InputIconChecked dangerouslySetInnerHTML={{ __html: CheckIcon }} ></Z.InputIconChecked>)}
                </Z.InputIconCheckedContainer>

                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning &&
                    <S.Error>{warning}</S.Error>))}

            </S.InputWrapper>
        </S.Row>
    );
};


const renderSelect = ({
    id,
    name,
    input,
    label,
    withLabel,
    options,
    className,
    meta: { touched, error, warning, valid, submitFailed }
}) => {

    const inputClass = classNames({
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid),
        'react-select-container': true
    });

    if (!input.value){
        input.value = 'PL';
        input.onChange('PL');
    }


    const inputWrapperClass = classNames('with-select', { [`${className}`]: Boolean(className) });
    return (
        <S.Row>
            <S.InputWrapper className={inputWrapperClass}>

                <S.Select as={Select}
                    id={id} 
                    name={name} 
                    options={options}
                    value={_find(options, {value: input.value})}
                    isSearchable={false}
                    classNamePrefix="react-select"
                    className={inputClass}
                    placeholder={label}
                    onChange={(props) => {
                        input.onChange(props.value);
                    }}
                    formatOptionLabel={option => <div>{option.label}</div>}
                />

                {withLabel && <S.Label>{label}</S.Label>}

                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning &&
                    <S.Error>{warning}</S.Error>))}

            </S.InputWrapper>
        </S.Row>
    )
};


const RegisterFormByMyIdStep1 = (props) => {


    const { handleSubmit, submitting, error, onSubmit, synchronousErrors, asynchronousErrors, checkErrorsForDataLayer, inputsReadOnly, userDataByMyId, formData, initialValues } = props;

    useEffect(() => {
        pushCustomEventToGoogleAnalytics({ 'event':'register-myid-1step'})    
    }, []);

    const countries = useMemo(() => {
        const countries = app.service.Language.__cachedDict.countries;
        const options = _map(countries, (countryName, countryCode) => {
            const option = {};
            option['label'] = countryName;
            option['value'] = countryCode;
            return option;
        });

        options.sort((a, b) => a['label'].localeCompare(b['label']));
        return options;
    }, []);

    const submit = async (formData, dispatch) => {
        try {
            await dispatch(isValueUnique("personalIdentificationNumber", formData.personalIdentificationNumber));
        } catch (error) {
            throw new SubmissionError({ personalIdentificationNumber: [translation(error)] });
        }
        try {
            await dispatch(isValueUnique("passport-number", formData.documentNumber));
        } catch (error) {
            throw new SubmissionError({ documentNumber: [translation(error)] });
        }
        try {
            await dispatch(isValueUnique("bankAccount", formData.bankAccountNumber));
        } catch (error) {
            throw new SubmissionError({ bankAccountNumber: [translation(error)] });
        }
        onSubmit();
    };

    return (
        <Z.RegisterFormByMyIdStep1  onSubmit={handleSubmit(submit)} autocomplete="off" noValidate>

            <Field
                name="firstName"
                type="text"
                component={renderField}
                label={translation('register_firstName')}
                focusLabel={translation('register_firstName')}
                disabled= { inputsReadOnly.indexOf(RegisterByMyIdAttributes.FIRST_NAME) > -1  }
            />

            <Field
                name="lastName"
                type="text"
                component={renderField}
                label={translation('register_lastName')}
                focusLabel={translation('register_lastName')}
                disabled= { inputsReadOnly.indexOf(RegisterByMyIdAttributes.LAST_NAME) > -1  }
            />

            <Field
                name="dateOfBirth"
                component={renderField}
                label={translation('account_registerFormByMyId_dateOfBirth')}
                focusLabel={translation('account_registerFormByMyId_dateOfBirth')}
                disabled= { inputsReadOnly.indexOf(RegisterByMyIdAttributes.DATE_OF_BIRTH) > -1  }
            />

            <Field
                name="personalIdentificationNumber"
                type="text"
                component={renderField}
                label={translation('register_registerForm_personalIdentificationNumber')}
                focusLabel={translation('register_registerForm_personalIdentificationNumber')}
                disabled= { inputsReadOnly.indexOf(RegisterByMyIdAttributes.PERSONAL_IDENTIFIER) > -1 }
            />

            <Field
                name="documentType"
                type="text"
                component={renderField}
                label={translation('register_registerForm_documentType')}
                focusLabel={translation('register_registerForm_documentType')}
                disabled= { inputsReadOnly.indexOf(RegisterByMyIdAttributes.DOCUMENT_TYPE) > -1  }
            />

            <Field 
                 name="documentNumber"
                 type="text"
                 component={renderField}
                 label={translation('register_registerForm_documentNumber')}
                 focusLabel={translation('register_registerForm_documentNumber')}
                 disabled= { inputsReadOnly.indexOf(RegisterByMyIdAttributes.DOCUMENT_NUMBER) > -1  }
             />

            <Field 
                 name="documentExpirationDate"
                 type="text"
                 component={renderField}
                 label={translation('register_registerForm_documentExpirationDate')}
                 focusLabel={translation('register_registerForm_documentExpirationDate')}
                 disabled= { inputsReadOnly.indexOf(RegisterByMyIdAttributes.DOCUMENT_EXPIRY_DATE) > -1  }
             />


            { initialValues.countryOfBirth ?
                <Field 
                    name="countryOfBirth"
                    type="text"
                    component={renderField}
                    label={translation('register_registerForm_countryOfBirth')}
                    focusLabel={translation('register_registerForm_countryOfBirth')}
                    disabled= { inputsReadOnly.indexOf(RegisterByMyIdAttributes.COUNTRY_OF_BIRTH) > -1  }
                />
                :
                <Field
                    name="countryOfBirth"
                    component={renderSelect}
                    id="countryOfBirth"
                    withLabel={true}
                    options={countries}
                    label={translation('register_registerForm_countryOfBirth')}
                />
            }

            { initialValues.nationality ? 
                <Field 
                    name="nationality"
                    type="text"
                    component={renderField}
                    label={translation('register_registerForm_nationality')}
                    focusLabel={translation('register_registerForm_nationality')}
                    disabled= { inputsReadOnly.indexOf(RegisterByMyIdAttributes.NATIONALITY) > -1  }
                />
                :
                <Field
                    name="nationality"
                    component={renderSelect}
                    id="nationality"
                    withLabel={true}
                    options={countries}
                    label={translation('register_registerForm_nationality')}
                />
            }

            <Field
                name="city"
                type="text"
                component={renderField}
                label={translation('register_registerForm_city')}
                focusLabel={translation('register_registerForm_city')}
                disabled= { inputsReadOnly.indexOf(RegisterByMyIdAttributes.ADDRESS_CITY) > -1  }
            />

            <Field
                name="street"
                type="text"
                component={renderField}
                label={translation('register_registerForm_street')}
                focusLabel={translation('register_registerForm_street')}
                disabled= { inputsReadOnly.indexOf(RegisterByMyIdAttributes.ADDRESS_STREET) > -1  }
            />

            <Field
                name="number"
                type="text"
                component={renderField}
                label={translation('register_registerForm_addressNumber')}
                focusLabel={translation('register_registerForm_addressNumber')}
                disabled= { inputsReadOnly.indexOf(RegisterByMyIdAttributes.ADDRESS_NUMBER) > -1  }
            />

            <Field
                name="postalCode"
                type="text"
                component={renderField}
                label={translation('register_registerForm_postalCode')}
                focusLabel={translation('register_registerForm_postalCode')}
                disabled= { inputsReadOnly.indexOf(RegisterByMyIdAttributes.POSTAL_CODE) > -1  }
            />

            { initialValues.country ? 
                <Field
                    name="country"
                    type="text"
                    component={renderField}
                    label={translation('register_registerForm_country')}
                    focusLabel={translation('register_registerForm_country')}
                    disabled= { inputsReadOnly.indexOf(RegisterByMyIdAttributes.ADDRESS_COUNTRY_NAME) > -1  }
                />
                :
                <Field
                    name="country"
                    component={renderSelect}
                    id="country"
                    withLabel={true}
                    options={countries}
                    label={translation('register_registerForm_country')}
                />
            }

            <Field 
                name="bankAccountNumber"
                type="text"
                component={renderField}
                label={translation('register_registerForm_bankAccountNumber')}
                focusLabel={translation('register_registerForm_bankAccountNumber')}
                disabled= { inputsReadOnly.indexOf(RegisterByMyIdAttributes.BANK_ACCOUNT_NUMBER) > -1  }
            />

            <Field 
                name="identifier"
                type="text"
                component={renderField}
                label={translation('register_registerForm_bankAccountNumber')}
                focusLabel={translation('register_registerForm_bankAccountNumber')}
                disabled = {true}
            />

            <S.SubmitButton type="submit" className="next" disabled={submitting} onClick={() => checkErrorsForDataLayer(synchronousErrors, asynchronousErrors)}>{translation('account_registerForm_next')}</S.SubmitButton>

            <S.AdditionalInfoRegByMyId>{translation('account_registerForm_contact')}</S.AdditionalInfoRegByMyId>
            <S.LinkHelp to='/pages/pomoc' onClick={() => chatButton.onClick()}>{translation('account_registerForm_contactLink')}</S.LinkHelp>

        </Z.RegisterFormByMyIdStep1>
    );
}


export default reduxForm({
            form: 'registerByMyIdForm',
            destroyOnUnmount: false,
            forceUnregisterOnUnmount: true,
            validate  
})(RegisterFormByMyIdStep1)