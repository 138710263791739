import React, {Component} from 'react';
import S from 'StyledPaymentList.js';
import {connect} from 'react-redux';
import ProviderContent from 'ProviderContent.js';
import {CSSTransition} from 'react-transition-group';
import {translation} from 'helpers/utilsHelper.js';
import paymentPrepareTransaction from 'paymentPrepareTransaction.enum.js';

class PaymentsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            drawing: [],
            selectedProvider: null
        };
    }

    componentDidMount = async() => {
        try {
            this.drawComponents();
        } catch (error) {
            console.log('DrawComponent error:' + error);
        }
    };

    drawComponents = (activeProvider) => {

        const {paymentData, userData, isLogged} = this.props;
        const {PROVIDER}  = paymentPrepareTransaction;
        this.setState({drawing: []});
        let temp = [];

        temp.push(
            paymentData.map((provider, index) => {
                const providerForm = PROVIDER[provider.providerId];
                const {providerId} = provider;
                let {selectedProvider} = this.state;
                let active = false;
                let customProps = {};

                if (providerForm && 'minatureComponent' in providerForm) {
                    const {img} = providerForm.minatureComponent;

                    if (img) {
                        customProps['img'] = img;
                    }
                }

                if (activeProvider == index) {
                    if (selectedProvider != activeProvider) {
                        active = true;
                        this.setState({selectedProvider: index});
                    } else {
                        active = false;
                        this.setState({selectedProvider: null});
                    }
                }

                return (
                    <MinatureProviderComponent
                        onClick={() => this.activeProvider(index)}
                        active={active}
                        index={index}
                        key={providerId}
                        providerId={providerId}
                        img={customProps.img}
                        providerForm={providerForm}
                        paymentData={paymentData[index]}
                        userData={userData}
                        isLogged={isLogged}
                    ></MinatureProviderComponent>
                )

            })
        )

        this.setState(state => (state.drawing = temp, state));
    }

    activeProvider = (index) => {
        this.setState(state => (state.drawing = this.drawComponents(index), state));
    }

    render() {
        const {paymentData, miniature, userData, isLogged} = this.props;
        const {PROVIDER}  = paymentPrepareTransaction;

        if (miniature) {
            return (
                <S.PaymentsList>
                    {this.state.drawing.map((item, index) => <Item item={item} key={index}></Item>)}
                </S.PaymentsList>
            );
        } else {
            return (
                <S.PaymentsList>
                    {paymentData.map((provider, index) => <ProviderContent
                        key={provider.providerId}
                        providerForm={PROVIDER[paymentData[index].providerId]}
                        paymentData={paymentData[index]}
                        limits={PROVIDER[paymentData[index].providerId]}
                        userData={userData}
                        isLogged={isLogged}
                        form ={'providerContentForm_' + provider.providerId}
                    />)}
                </S.PaymentsList>
            );
        }
    }
}

const mapStateToProps = (state, props) => {
    const {ProviderContent:{isPending, hasError, error}} = state;

    return {
        isPending,
        hasError,
        error
    }
};

const MinatureProviderComponent = ({img, paymentData, providerForm, userData, isLogged, providerId, index, active, onClick}) => {
    const MOBILE_URL = ENV == 'DEV' ? '/' : process.env.MOBILE_URL;
    const src = MOBILE_URL + "images/payments/" + img;
    const {providerName} = providerForm || {};
    return (
        <S.MinatureProviderComponent>
            <div onClick={() => onClick(index)}>
                <div>
                    <img src={src}/>
                </div>
                <div>
                    <span>{providerName ? translation(`${providerName}${providerId}`) : paymentData.providerName}</span>
                </div>
            </div>
            <CSSTransition
                in={active}
                timeout={1000}
                classNames="provider-transition"
                unmountOnExit
                apper>
                <ProviderContent
                    key={providerId}
                    providerForm={providerForm}
                    paymentData={paymentData}
                    userData={userData}
                    isLogged={isLogged}
                    form ={'providerContentForm_' + providerId}
                />
            </CSSTransition>
        </S.MinatureProviderComponent>
    )
}

const Item = ({item}) => {
    return (
        <S.Item>
            {item}
        </S.Item>
    )
}

export default connect(mapStateToProps)(PaymentsList);
