const authMiddleware = ({dispatch, getState}) => (next) => (action) => {
    const {type, payload} = action;
    if (type == 'USER_SIGNIN_SUCCESS') {
        app.service.Auth.customerData = payload.user;
        app.events.broadcast(app.enumerator.Events.ON_AUTH_CHANGE, {});
    } else if (type == 'USER_LOGOUT') {
        app.service.Auth.customerData = null;
        app.events.broadcast(app.enumerator.Events.ON_AUTH_CHANGE, {});
    }
    return next(action);
};

export default authMiddleware;