import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  padding: 3.5rem 0 0 0;
`

export const Iframe = styled.iframe`
  border: 0;
  flex-grow: 1;
  height: ${({ windowHeight = 0 }) => `calc(${windowHeight}px - 56px - 56px - 56px)`};
`

export const Nav = styled.nav`
  flex-basis: 100%;
  flex-shrink: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 3.5rem;
  z-index: 3;
`

export const NavBar = styled.button`
  align-items: center;
  background-color: #343434;
  border: 0;
  color: #FFFFFF;
  display: flex;
  height: 3.5rem;
  justify-content: space-between;
  margin: 0;
  overflow: hidden;
  padding: 0 1rem;
  position: relative;
  width: 100%;
  z-index: 1;

  ::after {
    border: 1px solid #FF9505;
    bottom: .125rem;
    content: '';
    left: .125rem;
    position: absolute;
    right: .125rem;
    top: .125rem;
  }
`

export const NavBarToggle = styled.div`
  height: 3.5rem;
  position: relative;
  transform: ${({ isNavOpen }) => `scale(${isNavOpen ? '-1, -1' : '1, 1'})`};
  transition: transform .15s ease-in-out;
  width: 3.5rem;

  ::after {
    border: 0.25rem solid #FF9505;
    border-top: 0;
    border-left: 0;
    content: '';
    height: 1rem;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%) translateY(-.25rem) rotate(45deg);
    width: 1rem;
  }
`

export const NavList = styled.ul`
  background-color: #343434;
  left: 0;
  list-style: none;
  margin: 0;
  opacity: ${({ isNavOpen }) => isNavOpen ? '1' : '0'};
  padding: 0;
  pointer-events: ${({ isNavOpen }) => isNavOpen ? 'all' : 'none'};
  position: absolute;
  right: 0;
  top: 3.5rem;
  transform: ${({ isNavOpen }) => `translateY(${isNavOpen ? '0' : '-2rem'})`};
  transition: all .15s ease-in-out;
  visibility: ${({ isNavOpen }) => isNavOpen ? 'visible' : 'hidden'};
`

export const NavItem = styled.li`
`

export const NavButton = styled.button`
  background-color: transparent;
  border: 0;
  color: ${({ isActive }) => isActive ? 'white' : 'lightgrey'};
  margin: 0;
  padding: 1rem;
  width: 100%;
`
