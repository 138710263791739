import {formatDate} from 'datesHelper.js';
const today = new Date();

export default (requests) => {
    return {
        getDocumentScans: () => {
            return requests.get('/document/scans');
        },
        getDocumentTypes: () => {
            return requests.get('/document/type');
        },
        getCustomerBettingStatus: () => {
            return requests.get(`/customer/session/betting-status`);
        },
        getCustomerSegmentStatus: () => {
            return requests.get(`/customer/account/segmentation/status`);
        },
        getCustomerLimits: () => {
            return requests.get(`/customer/account/limits/data`);
        },
        updateCustomerLimits: (data) => {
            return requests.put(`/customer/account/limits`, data);
        },
        acceptRegulations: () => {
            return requests.post(`/customer/session/accept-regulations`);
        },
        getGiifTypes: () => {
            return requests.get('/navigation/giif-types');
        },
        getPersonalData: () => {
            return requests.get('/customer/account/personal-data');
        },
        getVerificationStatus: () => {
            return requests.get('/customer/verification/status');
        },
        getValidityCustomRegisterToken: (token) => {
            return requests.get(`customer/account/custom-register/validate?token=${token}`);
        },
        cashierRegisterCustomer: (data) => {
            return requests.post('/customer/account/custom-register', data);
        },
        uploadDocumentScan: (data) => {
            return requests.post('/document/scans', data);
        },
        updatePersonalData: (data) => {
            return requests.post(`/customer/account/change-personal-data/`, data);
        },
        isValueUnique: (fieldName, fieldValue) => {
            return requests.get(`/customer/account/is-value-unique/${fieldName}/${fieldValue}`);
        },
        getSessionHistory: ({endDate = today, startDate = today, limit = 10, currentPage = 0} = {}) => {
            const dateFrom = formatDate(startDate, 'dd-MM-yyyy');
            const dateTo = formatDate(endDate, 'dd-MM-yyyy');
            const offset =  currentPage * limit;
            return requests.get(`/customer/session/session-history/${dateFrom}/${dateTo}/?limit=${limit}&offset=${offset}`);
        },
        getCustomerData: ({ keepSessionAlive = false, updateBalance = false }) => {
            return requests.get(`/customer/session/customerData/${keepSessionAlive}/${updateBalance}`)
        },
        uploadCustomerAvatar: (data) => {
            return requests.post(`/customer/avatar`, data);
        },
        getCustomerAvatar: () => {
            return requests.get(`/customer/avatar`);
        },
        getPublicRegConsents: (containersNames) => {
            return requests.get(`/customer/consents/tags/public?names=${containersNames}`);
        },
        updateCustomerConstans:(data)=>{
            return requests.post(`/customer/consents/definitions`,data)
        },
        getCustomerPromotions:()=>{
            return requests.get(`/customer/consents/containers/PROMOTIONS`)
        },
        getMyIdStatus:()=>{
            return requests.get(`/customer/identity-resolver/my-id`)
        },
        closeCustomerAccount: () => {
            return requests.post(`/customer/account/close-account`)
        },
    }
}