
export default (requests) => {
    return {
        getOffersCanceledOnWithdrawal: () => {
            return requests.get(`public/offers/cancel/withdrawal`);
        },
        getCustomerOfferBalancesWithDetails: () => {
            console.log('call request...');
            return requests.get('customer/account/customerOfferBalance/details');
        },
        withdrawCustomerOfferBalance: ({offerDefinitionId}={}) => {
            return requests.get(`customer/account/customerOfferBalance/withdraw/${offerDefinitionId}`);
        }

    }
};
