import {getBtagCookie} from 'helpers/cookiesHelper.js';

export const LOAD_VIRTUAL_GAMES_LIST = 'LOAD_VIRTUAL_GAMES_LIST'; 
export const LOAD_VIRTUAL_GAME = 'LOAD_VIRTUAL_GAME';
export const SET_VIRTUAL_PROVIDER_LIST = 'SET_VIRTUAL_PROVIDER_LIST';

const integrationIncomeAccessGetBtag = () => {
    return getBtagCookie();
};

const integrationIncomeAccessAcitveIframe = (customerId, ref) => {
    if (getBtagCookie()) {
        let src  = process.env.INTEGRATION_INCOME_ACCESS_PIXEL_SRC
        ref.current.src = src.replace("[playerid]", parseInt(customerId.value) ? parseInt(customerId.value) : parseInt(customerId));
    }
};

const addIncomeAccessData = (registerData) => {
    const incomeAccess = process.env.INTEGRATION_INCOME_ACCESS ? JSON.parse(process.env.INTEGRATION_INCOME_ACCESS) : undefined;
    const btag = integrationIncomeAccessGetBtag();
    
    if (incomeAccess && btag) {
        registerData.clickId = btag;
        registerData.affiliateId = btag;
    }

    return registerData;
};

const activateSportRadarEventsTrack = (type, modificator, payload) => {
    if (typeof srtmCommands != 'undefined') {
        if(app.config.shouldUnshiftSportRadarEvent){
            srtmCommands.unshift({
                event: "track." + modificator + "." + type,
                payload: payload
            });
        }else{
            srtmCommands.push({
                event: "track." + modificator + "." + type,
                payload: payload
            });
        }
    }
   
}

const setIncomeAccessIframe = (customerId, ref) => {
    const incomeAccess = process.env.INTEGRATION_INCOME_ACCESS ? JSON.parse(process.env.INTEGRATION_INCOME_ACCESS) : undefined;
    const btag = integrationIncomeAccessGetBtag();
    if (incomeAccess && btag) {
        integrationIncomeAccessAcitveIframe(customerId, ref);
    }
};

const getVirutalGames = (provider, format) => {
    return async(dispatch, getState, {IntegrationApi}) => {

        dispatch(loadVirtualGamesPending());

        try {
            const data = await IntegrationApi.getVirutalGames(provider, format);
            dispatch(loadVirtualGamesSuccess(data));
            return data;

        } catch (error) {
            dispatch(loadVirtualGamesFailure(error));
            throw error;
        }
    }
};

const getVirutalGame = (provider, gameId) => {
    return async(dispatch, getState, {IntegrationApi}) => {

        dispatch(loadVirtualGamePending());

        try {
            const data = await IntegrationApi.getVirutalGame(provider, gameId);
            dispatch(loadVirtualGameSuccess(data));
            return data;

        } catch (error) {
            dispatch(loadVirtualGameFailure(error));
            throw error;
        }
    }
};

const getSimpleIframeGame = (provider, gameId = null) => async (dispatch, getState, { IntegrationApi }) => {
  dispatch(loadSimpleIframeGamePending())

  try {
    const data = await IntegrationApi.getSimpleIframeGame(provider, gameId)

    dispatch(loadSimpleIframeGameSuccess(data.data))

    return data.data
  } catch (error) {
    dispatch(loadSimpleIframeGameFailure(error))
    throw error
  }
}

const getIntegration = (config) => async (dispatch, _, { IntegrationApi }) => {
    try {
        const { data } = await IntegrationApi.getIntegration(config)

        return data
    } catch (error) {
        return error
    }
}

const getGoldenRaceGame = ({ device, demo, countryCode }) => async (_, __, { IntegrationApi }) => {
    try {
        const { data } = await IntegrationApi.getGoldenRaceGame({ device, demo, countryCode })
        const result = JSON.parse(data)

        return result
    } catch ({ message }) {

    }
}

const sendParagonexAuthorizationData = (postData) => {
    return async(dispatch, getState, {IntegrationApi}) => {

        try {
            const {code, data} = await IntegrationApi.sendParagonexAuthorizationData(postData);
            if (code == 200) {
                return data
            }

            throw {message: `error_${code}`};
        } catch (error) {
            throw error;
        }
    }
};

const sendParagonexTransferData = (postData) => {
    return async(dispatch, getState, {IntegrationApi}) => {

        try {
            const {code, data} = await IntegrationApi.sendParagonexTransferData(postData);
            if (code == 200) {
                return data
            }

            throw {message: `error_${code}`};
        } catch (error) {
            throw error;
        }
    }
};


const getBitvilleIframeData = (demo, device) => {
    return async(dispatch, getState, {IntegrationApi}) => {
        try {
            const {code, data} = await IntegrationApi.getBitvilleIframeData(device, demo)
            if (code == 200) {
                return data;
            }

            throw {message: `error_${code}`};
        } catch (error ) {
            return error;
        }
    }
}

const loadSimpleIframeGamePending = () => ({
  type: `${LOAD_VIRTUAL_GAME}_PENDING`
})

const loadSimpleIframeGameSuccess = gameUrl => ({
  type: `${LOAD_VIRTUAL_GAME}_SUCCESS`,
  payload: {
    gameUrl
  }
})

const loadSimpleIframeGameFailure = error => ({
  type: `${LOAD_VIRTUAL_GAME}_FAILURE`,
  payload: {
    error
  }
})

const loadVirtualGamesFailure = (error) => {
    return {
        type: `${LOAD_VIRTUAL_GAMES_LIST}_FAILURE`,
        payload: {error}
    }
};

const loadVirtualGamesSuccess = (gamesList) => {
    return {
        type: `${LOAD_VIRTUAL_GAMES_LIST}_SUCCESS`,
        payload: {gamesList}
    }
};

const loadVirtualGamesPending = () => {
    return {
        type: `${LOAD_VIRTUAL_GAMES_LIST}_PENDING`
    }
};

const loadVirtualGameFailure = (error) => {
    return {
        type: `${LOAD_VIRTUAL_GAME}_FAILURE`,
        payload: {error}
    }
};

const loadVirtualGamePending = () => {
    return {
        type: `${LOAD_VIRTUAL_GAME}_PENDING`
    }
};

const loadVirtualGameSuccess = (gameUrl) => {
    return {
        type: `${LOAD_VIRTUAL_GAME}_SUCCESS`,
        payload: {gameUrl}
    }
};

export {
  getVirutalGames,
  getVirutalGame,
  getSimpleIframeGame,
  getIntegration,
  getGoldenRaceGame,
  sendParagonexAuthorizationData,
  sendParagonexTransferData,
  addIncomeAccessData,
  integrationIncomeAccessGetBtag,
  integrationIncomeAccessAcitveIframe,
  setIncomeAccessIframe,
  getBitvilleIframeData,
  activateSportRadarEventsTrack
};
