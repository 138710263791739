import _map from 'lodash/map';

const NRBvalidatior = (nrb) => {
    nrb = nrb.replace(/[^0-9]+/g, '');
    var Wagi = new Array(1, 10, 3, 30, 9, 90, 27, 76, 81, 34, 49, 5, 50, 15, 53, 45, 62, 38, 89, 17, 73, 51, 25, 56, 75, 71, 31, 19, 93, 57);
    if (nrb.length == 26) {
        nrb = nrb + "2521";
        nrb = nrb.substr(2) + nrb.substr(0, 2);
        var Z = 0;
        for (var i = 0; i < 30; i++) {
            Z += nrb[29 - i] * Wagi[i];
        }
        if (Z % 97 == 1) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

const validateDO = (numer) => {

    var numer = numer.replace(/\s+/, '');

    if (numer == null || numer.length != 9) {
        return false;
    }

    numer = numer.toUpperCase();

    var letterValues = [
        '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
        'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
        'U', 'V', 'W', 'X', 'Y', 'Z'];

    function getLetterValue(letter) {
        for (var j = 0; j < letterValues.length; j++)
            if (letter == letterValues[j])
                return j;
        return -1;
    }

    //Check seria
    for (var k = 0; k < 3; k++) {
        if (getLetterValue(numer[k]) < 10)
            return false;
    }

    //Check number
    for (var i = 3; i < 9; i++) {
        if (getLetterValue(numer[i]) < 0 || getLetterValue(numer[i]) > 9) {
            return false;
        }
    }

    //sprawdz cyfre controlna
    var sum = 7 * getLetterValue(numer[0]) +
        3 * getLetterValue(numer[1]) +
        1 * getLetterValue(numer[2]) +
        7 * getLetterValue(numer[4]) +
        3 * getLetterValue(numer[5]) +
        1 * getLetterValue(numer[6]) +
        7 * getLetterValue(numer[7]) +
        3 * getLetterValue(numer[8]);

    sum %= 10;

    if (sum != getLetterValue(numer[3]))
        return false;

    return true;
};

const isPeselValid = (pesel)=>{

        const peselToIntArray = (pesel)=>{
            return _map(pesel.split(''), Number);
        };

        var peselArray = peselToIntArray(pesel);

        try {

            if(pesel.length == 11) {
                for (var i = 0; i < 11; i++) {

                    peselArray[i] = parseInt(pesel.substring(i, i + 1));

                    if (isNaN(peselArray[i])) {
                        return false;
                    }

                }

                var weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 1];
                var sum = 0;

                for (var i = 0; i < 11; i++) {
                    sum += weights[i] * peselArray[i];
                }

                if ((sum % 10) != 0) {
                    return false;
                }
            } else {
                return false;
            }

        } catch (err) {
            return false;
        }

        return true;
};

const isPeselAdult = (pesel)=>{

    const peselToIntArray = (pesel)=>{
       return _map(pesel.split(''), Number);
    };

    const getYear = pesel => {
        const peselArray = peselToIntArray(pesel);
        let year = 1900 + peselArray[0] * 10 + peselArray[1];

        if (peselArray[2] >= 2 && peselArray[2] < 8) {
            year += Math.floor(peselArray[2] / 2) * 100;
        }
        if (peselArray[2] >= 8) {
            year -= 100;
        }
        return year;
    };

    const getMonth = pesel => {
        const peselArray = peselToIntArray(pesel);
        return (peselArray[2] % 2) * 10 + peselArray[3];
    };

    const getDay = pesel => {
        const peselArray = peselToIntArray(pesel);
        return peselArray[4] * 10 + peselArray[5];
    };

    const year = getYear(pesel);
    const month = getMonth(pesel);
    const day = getDay(pesel);

    const birthDate = `${year}-${month}-${day}`;
    return isAdult(birthDate, 18);
};

const isAdult = (value, maxAge) => {
  let birthday = value;
  if(typeof birthday == "string"){
    const dateParts = birthday.split("-");
    birthday = new Date(dateParts[0], (dateParts[1] - 1), dateParts[2]);
  }
  const today = new Date();
  let age = today.getUTCFullYear() - birthday.getUTCFullYear();
  const m = today.getMonth() - birthday.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
    age--;
  }
  return age >= maxAge;
};

export {NRBvalidatior, validateDO, isAdult, isPeselAdult, isPeselValid}
