import axios from 'axios';
import store from 'store';

import AccountBonusApiWrapper from 'AccountBonusApi.js';
import AccountCashbackApiWrapper from 'AccountCashbackApi.js';
import AccountWalletApiWrapper from 'AccountWalletApi.js';
import AuthApiWrapper from 'AuthApi.js';
import CmsApiWrapper from 'CmsApi.js';
import CustomerApiWrapper from 'CustomerApi.js';
import FreebetApiWrapper from 'FreebetApi.js';
import IntegrationApiWrapper from 'IntegrationApi.js';
import OffersApiWrapper from 'OffersApi.js';
import PaymentApiWrapper from 'PaymentApi.js';
import TransactionApiWrapper from 'TransactionApi.js';
import AccountFreebetApiWrapper from 'AccountFreebetApi.js';
import AccountBetHistoryApiWrapper from 'AccountBetHistoryApi.js';
import AccountVirtualBetHistoryApiWrapper from 'AccountVirtualBetHistoryApi.js';
import FavouritesApiWrapper from 'FavouritesApi.js';
import OtherApiWrapper from 'OtherApi.js';
import LiveApiWrapper from 'LiveApi.js';
import MarketApiWrapper from 'MarketApi.js';

import {setSessionCookie, getLanguageCookie} from 'helpers/cookiesHelper.js';

const platform = location.hostname.split('.')[0];
const API_URL = (platform == "rc" && CLIENT == 'etoto') 
    ? process.env.API_URL.replace('api', `${platform}-api`) 
    : (platform == 'rc' && CLIENT == 'editec')
    ? location.origin + '/rest'
    : process.env.API_URL;
const LIVE_API_URL = process.env.API_LIVE_BETTING_URL;
const EXTERNAL_BET_HISTORY_URL = process.env.EXTERNAL_BET_HISTORY_URL;
const responseBody = res => res.data;

const requests = function() {

    return {
        get: (url, config) =>
            this.get(`${url}`, config).then(responseBody),
        post: (url, body, config) =>
            this.post(`${url}`, body, config).then(responseBody),
        put: (url, body, config) =>
            this.put(`${url}`, body, config).then(responseBody),
        delete: (url, config) =>
            this.delete(`${url}`, config).then(responseBody),
    }
};

const baseInstance = axios.create({baseURL: API_URL});
const customerInstance = axios.create({baseURL: API_URL});
const externalBetHistoryInstance = axios.create({baseURL: EXTERNAL_BET_HISTORY_URL});
const liveEventInstance = axios.create({baseURL: LIVE_API_URL});
const marketInstance = axios.create({baseURL: API_URL});

customerInstance.interceptors.response.use(response => {
    const {headers, data:{code}} = response;

    if ('x-odds-session' in headers) {
        setSessionCookie(headers['x-odds-session']);
    }

    if (code == 456 || code == 476) {
        const {dispatch, getState} = store;
        const {Auth:{isLogged}} = getState();
        if (isLogged) {
            console.log('logout action in response interceptor!');
            dispatch(logOutUser());
        }
    }

    return response;
}, error => {
    return Promise.reject(error);
});

customerInstance.interceptors.request.use(config => {
    const sessionCookie = Cookies.get('X-ODDS-SESSION');

    const headers = {
        'Request-Language': (getLanguageCookie() || app.config.mainCountryCode.toLowerCase())
    };

    if (sessionCookie) {
        headers['X-ODDS-SESSION'] =  sessionCookie;
    }
    const brandId = app.config.brandId || app.enumerator.Brands.BRANDSMAP[app.config.mainCountryCode.toLowerCase()];
    if (brandId) {
        headers['X-Brand-Data'] = brandId;
    }

    config['headers'] = {...config['headers'] ,...headers};
    return config;
}, error => {
    return Promise.reject(error);
});

externalBetHistoryInstance.interceptors.request.use(function (config) {
    const sessionCookie = Cookies.get('X-ODDS-SESSION');
    const headers = {};

    sessionCookie ? headers['X-ODDS-SESSION'] = sessionCookie : null;
    config['headers'] = {...config['headers'] ,...headers};
    
    return config;
}, function (error) {
    return Promise.reject(error);
});

liveEventInstance.interceptors.request.use(config => {
    const headers = {
        'Request-Language': (getLanguageCookie() || app.config.mainCountryCode.toLowerCase())
    };

    config['headers'] = {...config['headers'] ,...headers};
    return config;
}, function (error) {
    return Promise.reject(error);
});

marketInstance.interceptors.request.use(config => {
    const headers = {
        'Request-Language': (getLanguageCookie() || app.config.mainCountryCode.toLowerCase())
    };

    config['headers'] = {...config['headers'] ,...headers};
    return config;
}, function (error) {
    return Promise.reject(error);
});

const AccountBonusApi = AccountBonusApiWrapper(requests.call(customerInstance));
const AccountCashbackApi = AccountCashbackApiWrapper(requests.call(customerInstance));
const AccountWalletApi = AccountWalletApiWrapper(requests.call(customerInstance));
const AuthApi = AuthApiWrapper(requests.call(customerInstance));
const CustomerApi = CustomerApiWrapper(requests.call(customerInstance));
const FreebetApi = FreebetApiWrapper(requests.call(customerInstance));
const PaymentApi = PaymentApiWrapper(requests.call(customerInstance));
const IntegrationApi = IntegrationApiWrapper(requests.call(customerInstance));
const CmsApi = CmsApiWrapper(requests.call(customerInstance));
const OffersApi = OffersApiWrapper(requests.call(customerInstance));
const TransactionApi = TransactionApiWrapper(requests.call(customerInstance));
const AccountFreebetApi = AccountFreebetApiWrapper(requests.call(customerInstance));
const AccountBetHistoryApi = AccountBetHistoryApiWrapper(requests.call(customerInstance));
const AccountVirtualBetHistoryApi = AccountVirtualBetHistoryApiWrapper(requests.call(externalBetHistoryInstance))
const FavouritesApi = FavouritesApiWrapper(requests.call(customerInstance));
const OtherApi = OtherApiWrapper(requests.call(baseInstance));
const LiveApi = LiveApiWrapper(requests.call(liveEventInstance));
const MarketApi = MarketApiWrapper(requests.call(marketInstance));

export {
    AccountBonusApi,
    AccountCashbackApi,
    AuthApi,
    IntegrationApi,
    CmsApi,
    CustomerApi,
    FreebetApi,
    PaymentApi,
    OffersApi,
    AccountFreebetApi,
    AccountBetHistoryApi,
    TransactionApi,
    AccountWalletApi,
    FavouritesApi,
    AccountVirtualBetHistoryApi,
    OtherApi,
    LiveApi,
    MarketApi
};

