import styled from 'styled-components';
import {rem} from 'polished';

const FwBold = ({theme:{$fwBold}}) => `${$fwBold}`;

const S = {}

S.BackHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
`;
S.BackButton = styled.div`
    width: 16px;
    height: 16px;
    margin-right: 20px;
`;
S.Image = styled.img``;
S.Title = styled.h1`
    font-size: ${rem('22px')};
    font-weight: ${FwBold};
`;

export default S;
