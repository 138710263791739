export default (requests) => {
    return {

        getVirutalGames: (provider, format) => {
            return requests.get(`/integration/${provider}/${format}`);
        },

        getVirutalGame: (provider, gameId) => {
            return requests.get(`/integration/${provider}/get_game?gameid=${gameId}`);
        },

        getSimpleIframeGame: (provider, gameId = null) => {
            return process.env.TVBET_START_URL_GAME && gameId
                ? requests.get(`/integrations/${provider}/iframe?gameId=${gameId}${Boolean(process.env.TVBET_WITH_REG_METHOD) ? '&regMethod=2' : ''}`)
                : requests.get(`/integrations/${provider}/iframe${Boolean(process.env.TVBET_WITH_REG_METHOD) ? '?regMethod=2' : ''}`
                );
        },

        getIntegration: ({ provider, method }) => {
            return requests.get(`/integration/${provider}/${method}`)
        },

        getGoldenRaceGame : ({ device = 'MOBILE', demo = true, countryCode = '' }) => {
            return requests.get(`/integrations/goldenrace/get_game/${device.toUpperCase()}?demo=${demo}&countryCode=${countryCode}`)
        }
    }
};
