/* global app, grMobileLoader */
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import query from 'query-string'
import { getGoldenRaceGame } from 'integrationActions.js'

const CONTAINER = 'golden-race-mobile-app'
const SCRIPT = 'golden-race-mobile-loader'

const languagesMap = {
  en: 'en-GB',
  es: 'es-ES',
  it: 'it-IT',
  fr: 'fr-BE',
  ja: 'ja-JP',
  ka: 'ka-GE',
  ko: 'ko-KR',
  ms: 'ms-MY',
  nl: 'nl-NL',
  pl: 'pl-PL',
  pt: 'pt-PT',
  ro: 'ro-RO',
  ru: 'ru-RU',
  th: 'th-TH',
  uk: 'uk-UA',
  vi: 'vi-VN',
  zh: 'zh-CN'
}

const GoldenRace = ({ isLogged }) => {
  const { lang, mainCountryCode: countryCode, goldenRace2Profile: profile, goldenRace2UISettings: settingsUI } = app.config

  const dispatch = useDispatch()

  const script = document.createElement('script')
  const container = useRef(null)

  const [url, setUrl] = useState(undefined)
  const [hash, setHash] = useState(undefined)
  const [hardwareId, setHardwareId] = useState(undefined)

  // Set up an iframe when script tag loads
  const setIframe = useCallback(() => {
    window.grLoader = grMobileLoader({
      language: languagesMap[lang.toLowerCase()],
      ...settingsUI,
      ...(!!hardwareId && { hwId: hardwareId }),
      ...(!!hash && { onlineHash: hash }),
      ...(!!profile && { profile }),
      onInit: () => {
        const { coupons } = query.parse(window.location.search)

        !!coupons && window.grLoader.clickOpenBetHistory()
      }
    })
  }, [lang, hardwareId, hash, profile])

  // Set up script tag
  const setScript = useCallback(() => {
    script.onload = setIframe
    script.id = SCRIPT
    script.src = url

    container?.current?.parentElement?.appendChild(script)
  }, [script, url, setIframe, container])

  // Place CONTAINER first thing when component mounts
  useEffect(() => {
    container.current.id = CONTAINER
  }, [container])

  // Get the script tag config
  useEffect(() => {
    const getIframe = async () => {
      const { url_script: url, online_hash: hash, hardware_id: hardwareId } = await dispatch(getGoldenRaceGame({
        device: 'MOBILE',
        demo: !isLogged,
        countryCode
      }))

      setHash(hash)
      setHardwareId(hardwareId)
      setUrl(url)
    }

    getIframe()
  }, [dispatch, isLogged])

  useEffect(() => {
    !!url && setScript()
  }, [url, setScript])

  return (
    <div ref={container} />
  )
}

const mapStateToProps = ({ Auth: { isLogged } }) => {
  return {
    isLogged
  }
}

export default connect(mapStateToProps)(GoldenRace)