import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import {USER_LOGOUT, USER_SIGNIN, BRANDS_DATA} from 'authActions.js';

let initialState = {
    isPending: false,
    isLogged: false,
    error: null,
    user: null,
    brandData: null
};

export default typeToReducer({
    [USER_LOGOUT]: (state, action) => {
        return {...state, isLogged: false, user: null}
    },

    [USER_SIGNIN]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {user}}) => {
            return {...state, isLogged: true, isPending: false, user};
        }
    },

    [BRANDS_DATA] : {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {brandData}}) => {
            return {...state, isPending: false, brandData};
        }
    }

}, initialState)