import styled from 'styled-components';
import { Button, Input, Form } from 'global/styles/styles.js';


const baseFont = `12px`;
const imagePath = ENV == 'DEV' ? '/' : process.env.MOBILE_URL;


const TooltipButtonColor = ({ theme: { $registerTooltipButtonColor } }) => `${$registerTooltipButtonColor}`;
const FormBackground = ({ theme: { $registerFormBackgroundColor } }) => $registerFormBackgroundColor;
const InputBorderColor = ({ theme: { $registerFormInputBorderColor } }) => `${$registerFormInputBorderColor}`;
const InputErrorColor = ({ theme: { $registerFormErrorColor } }) => `${$registerFormErrorColor}`;
const InputTextColor = ({ theme: { $registerFormInputColor } }) => `${$registerFormInputColor}`;
const InputBackground = ({ theme: { $registerFormInputBackgroundColor } }) => `${$registerFormInputBackgroundColor}`;
const InputPlaceholderColor = ({ theme: { $registerFormTextColor } }) => `${$registerFormTextColor}`;
const InputLabelColor = ({ theme: { $registerFormInputLabelColor } }) => `${$registerFormInputLabelColor}`;
const ModalBorderColor = ({ theme: { $registerFormModalBorderColor } }) => `${$registerFormModalBorderColor}`;

const FontFamilyBarlow = ({ theme: { $fontFamily } }) => `${$fontFamily}`;

const Z = {};

Z.RegisterFormByMyIdContainer = styled.div`
    color: ${({theme: {$registerFormStepChooseMethodColor}}) => $registerFormStepChooseMethodColor};
`;

Z.Header = styled.div`
    margin-top: 1rem;
    position: relative;
    padding: 0 1.06rem;
`;

Z.RegisterWizardFormTitle = styled.h2`
    text-align: center;
    font-size: 1.63rem;
    margin: 0 auto;
    color: ${InputBorderColor};
    font-weight: 400;
`;

Z.HeaderButton = styled(Button)`
    position: absolute;
    top: 0;
    display:flex;
    background-color: transparent;
    align-items: center;
    padding: 0;
    &.closeButton {
        right: 1.06rem;
    }
    &.backButton {
        left: 1.06rem;
    }
`;

Z.HeaderButtonIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.56rem;
    height: 1.56rem;
    cursor: pointer;
    & svg{
        width: 100%;
        & path{
            fill: ${InputTextColor} !important;
        }
    }
`;

Z.Body = styled.div`
    
`;

Z.RegisterFormByMyIdStep1 = styled(Form)` 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    background-color: ${({theme: {$registerFormBackgroundColor}}) => $registerFormBackgroundColor};
    padding: 1.19rem 1.19rem 1.3rem;
`;

Z.InputIconCheckedContainer = styled.div`
    position: absolute;
    right: 1rem;
    top: 1rem;
`;

Z.InputIconChecked = styled.div`
    width: 1rem;
    height: 1rem;
`;

export default Z;