import React, {Component} from 'react';
import {NotLogged} from 'global/styles/styles.js';
import {connect} from 'react-redux';
import {paymentProviders} from 'paymentActions.js';
import Loader from 'Loader.js';
import PaymentsList from 'PaymentsList.js';
import ProviderContent from 'ProviderContent.js';
import {translation} from 'helpers/utilsHelper.js';
import _size from 'lodash/size';
import paymentPrepareTransaction from 'paymentPrepareTransaction.enum.js';
import {providerSettingsSelector} from 'paymentsSelector.js';
import S from 'StyledDepositLoginPopup.js';

class DepositLoginPopup extends Component {

    componentDidMount = async() => {
        const {dispatch} = this.props;
        const {paymentCurrency} = paymentPrepareTransaction.LIST_SETTINGS;
        const currencyCode = process.env.MAIN_CURRENCY_CODE;

        try {
            paymentCurrency ? await dispatch(paymentProviders(currencyCode)) : await dispatch(paymentProviders()); 
        } catch (error) {
            console.log('paymentProviders error:' + error);
        }
    };

    static getDerivedStateFromError = (error) => {
        return {hasError: true};
    };

    render() {
        const {isPending, hasError, paymentProviders, user, isLogged} = this.props;
        const {LIST_SETTINGS:{miniature, visalbleDepositsIfNotLogged}, PROVIDER} = paymentPrepareTransaction;
        const paymentData = paymentProviders.deposits;
        const controller = app.getCurrentController();
        const DEFAULT_PROVIDER_ID = 103;

        if (hasError) {
            return <div>Error fallback component!</div>
        }
        
        if (isPending) {
            return <Loader/>
        }

        if (!isLogged && controller != 'AgentDeposit' && !visalbleDepositsIfNotLogged && !isPending) {
            return <NotLogged>{translation('payment_notLogged')}</NotLogged>
        }

        if (paymentData.length){
            let provider = paymentData.find((p)=> p.providerId == DEFAULT_PROVIDER_ID );
            return (
                <S.AccountDeposit onlyOne={true}>
                    <S.Row><S.Header>{translation('account_loggedPanel_depositLoginPopup_loginText')}{user.login}</S.Header></S.Row>
                    <S.Row><S.Text>{translation('account_loggedPanel_depositLoginPopup_mainText')}</S.Text></S.Row>
                    <S.Row><S.Text>{translation('account_loggedPanel_depositLoginPopup_balance')}:
                        <S.Balance>{user.balance}</S.Balance><S.Currency>{user.currencyCode}</S.Currency>
                    </S.Text></S.Row>
                    <ProviderContent 
                        key={provider.providerId} 
                        providerForm = {PROVIDER[provider.providerId]}
                        paymentData={provider}
                        userData={user}
                        isLogged={isLogged}
                        onlyOne = {true}
                        form ={'providerContentForm_' + provider.providerId}
                    />
                    <S.Row><S.Header><S.Button onClick={() => app.router.redirect('/payments')}>
                        {translation('account_loggedPanel_depositLoginPopup_moreOptions')} 
                    </S.Button></S.Header></S.Row>
                </S.AccountDeposit>
            )
        } else {
            return (
                <S.NoActiveProviders>
                    {translation('payments_noActiveProviders')}
                    <S.Row><S.Header><S.Button onClick={() => app.router.redirect('/payments')}>
                        {translation('account_loggedPanel_depositLoginPopup_moreOptions')} 
                    </S.Button></S.Header></S.Row>
                </S.NoActiveProviders>
            );
        }
    }
}

const mapStateToProps = (state, props) => {
    const {DepositLoginPopup:{isPending, error, hasError}, Auth:{isLogged, user}} = state;
    const paymentProviders = providerSettingsSelector(state, props);
    return {
        isPending,
        error,
        hasError,
        paymentProviders,
        user,
        isLogged
    }
};

export default connect(mapStateToProps)(DepositLoginPopup);
