/* global app */
import React, { useMemo } from 'react'
import { SBPayments } from 'sb-payments-totalbet'

const { Summary } = SBPayments

const PaymentSummary = () => {
  const lang = useMemo(() => `lang_${app.config.lang}_${app.config.mainCountryCode}_1_1`.toLowerCase(), [app.config.lang, app.config.mainCountryCode])

  return (
    <Summary lang={lang} />
  )
}

export default PaymentSummary
