import React, { useState, useMemo, useRef, useEffect } from 'react';
import S from 'StyledRegisterOptions.js';
import { translation } from 'helpers/utilsHelper.js';
import { useSelector } from 'react-redux';


import ContactForm from 'contact-form.svg';
import Bank from 'bank.svg';

const RegisterOptions = ({selectTraditionalRegisterMethod, selectMyIdRegisterMethod}) => {
    const { isMyIdActive } = useSelector(state => state?.Customer);

    return (
        <S.RegisterOptionsContainer> 
            <S.OptionContainer>
                <S.OptionLabelRow>
                     {<S.BankIcon className="" dangerouslySetInnerHTML={{__html: Bank}} />}
                     {translation('account_registerForm_myBank')}
                </S.OptionLabelRow>
                <S.OptionDescription dangerouslySetInnerHTML={{__html: translation('account_registerForm_myBankDescription')}}/>
                <S.OptionChooseBtn onClick={selectMyIdRegisterMethod} disabled={!isMyIdActive}>
                    {isMyIdActive? translation('account_registerForm_registerBtn') : translation('account_registerForm_registerBtn_disabled')}
                </S.OptionChooseBtn>
            </S.OptionContainer> 
            <S.OptionContainer>
                <S.OptionLabelRow>
                     {<S.ContactFormIcon className="" dangerouslySetInnerHTML={{__html: ContactForm}} />}
                     {translation('account_registerForm_contactForm')}
                </S.OptionLabelRow>
                <S.OptionDescription dangerouslySetInnerHTML={{__html: translation('account_registerForm_contactFormDescription')}}/>
                <S.OptionChooseBtn onClick={selectTraditionalRegisterMethod}>
                     {translation('account_registerForm_registerBtn')}
                </S.OptionChooseBtn>
            </S.OptionContainer>
        </S.RegisterOptionsContainer>);

};

export default RegisterOptions;

