import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, getFormValues, getFormSyncErrors } from 'redux-form';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import store from 'store';
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';
import { translation, redirect, pushCustomEventToGoogleAnalytics } from 'helpers/utilsHelper.js';
import useToggle from 'UseToggle.js';
import S from 'StyledRegisterWizardForm.js';
import TogglerIcon from 'register-tooltip-icon.svg';
import EyeIcon from 'register-eye-icon.svg';

const validate = (values) => validation(values, validators.REGISTER_STEP1);

const PasswordToggler = () => {
    const [passwordVisibility, togglePassword] = useToggle(false);

    useEffect(() => {
        const passwordFields = document.querySelectorAll('[name="password"]');
        if (passwordFields.length) {
            const passwordFieldsDOM = [...passwordFields];
            if (passwordVisibility) {
                passwordFieldsDOM.forEach(el => el.type = 'text');
            } else {
                passwordFieldsDOM.forEach(el => el.type = 'password');
            }
        }
    }, [passwordVisibility]);

    return (
        <S.PasswordToggler onClick={togglePassword}>
            <S.PasswordTogglerIcon 
                isActive={passwordVisibility} 
                dangerouslySetInnerHTML={{ __html: EyeIcon }} 
            />
        </S.PasswordToggler>
    );
};

const TooltipToggler = ({ openTooltip, name }) => {
    return (
        <S.TooltipToggler onClick={() => openTooltip(name)}>
            <S.TooltipTogglerIcon dangerouslySetInnerHTML={{ __html: TogglerIcon }}></S.TooltipTogglerIcon>
        </S.TooltipToggler>
    );
};

const renderField = ({ 
    input, 
    label, 
    focusLabel, 
    required, 
    type, 
    disabled, 
    openTooltip, 
    preventAutoComplete, 
    validInfo,
    meta: { touched, error, warning, valid, submitFailed } 
}) => {
    const isDisabled = disabled ?? false;

    const inputClass = classNames({
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid),
        'has-prefix-mobile': (input.name == 'mobile'),
    });

    return (
        <S.Row>
            <S.InputWrapper>
                {input.name === 'password' && <PasswordToggler />}

                {openTooltip && <TooltipToggler name={input.name} openTooltip={openTooltip} />}

                {(input.name === 'mobile' ?
                    <>
                        <span className="prefix-mobile">+48</span>
                        <S.Input {...input}
                            type={type}
                            required={required}
                            className={inputClass}
                            autoComplete={preventAutoComplete && "new-password"}
                            disabled={isDisabled}
                            as={NumberFormat}
                            mask="_"
                            format="###-###-###"
                            placeholder="___-___-___"
                        />
                    </> :
                    (input.name === 'password' ?
                        <S.Input {...input}
                            type={type}
                            required="required"
                            className={inputClass}
                            autoComplete={preventAutoComplete && "new-password"}
                            disabled={isDisabled}
                            onChange={input.onBlur}
                        /> :
                        <S.Input {...input}
                            type={type}
                            required="required"
                            className={inputClass}
                            autoComplete={preventAutoComplete && "new-password"}
                            disabled={isDisabled}
                        />
                    )
                )}

                <S.Label>
                    {(label && <span className="bottomLabel" dangerouslySetInnerHTML={{ __html: label }} ></span>)}
                    {(focusLabel && <span className="topLabel" dangerouslySetInnerHTML={{ __html: focusLabel }} ></span>)}
                </S.Label>

                {(input.name === 'password' 
                    ? <PasswordStrengthChecker error={error} touched={touched} />
                    : (touched || submitFailed) && (
                        (error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>)
                    )
                )}
                {validInfo && valid ? 
                    <S.ValidInfo>
                        <span dangerouslySetInnerHTML={{ __html: validInfo }} ></span>
                    </S.ValidInfo>
                    : null}
            </S.InputWrapper>
        </S.Row>
    );
};

const PasswordStrengthChecker = ({ error, touched }) => {
    return (
        <S.PasswordStrengthChecker>
            <PasswordStrengthField name="length" error={error} touched={touched}/>
            <PasswordStrengthField name="lowerCase" error={error} touched={touched}/>
            <PasswordStrengthField name="upperCase" error={error} touched={touched}/>
            <PasswordStrengthField name="digit" error={error} touched={touched} />
        </S.PasswordStrengthChecker>
    )
};

const PasswordStrengthField = ({ error, name, touched }) => {
    const fieldClass = classNames({
        'has-error': (touched && (error?.[0]?.name ?? false)),
        'no-touched': !touched
    });

    return (
        <S.PasswordField 
            name={name} 
            className={fieldClass} 
            dangerouslySetInnerHTML={{ __html: translation(`account_registerForm_${name}PasswordStrength`) }} 
        />
    )
};

const renderCheckbox = ({ 
    input, 
    label, 
    required, 
    indentation, 
    meta: { touched, error, warning, valid, submitFailed } 
}) => {
    const [fullText, setFullText] = useState(false);

    const inputClass = classNames({
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid)
    });

    const checkboxWrapperClass = classNames({
        'with-checkbox': true,
        'indentation': indentation
    })

    const cutHTMLRegex = /<[^>]*>/g;
    const htmlTags = label.match(cutHTMLRegex);
    let htmlTagsLength = 0;

    if(htmlTags) {
        for(let htmlTag of htmlTags) {
            htmlTagsLength += htmlTag.length
        }
    }  

    const lengthToCut = 104 + htmlTagsLength;

    const toggleFullText = () => {
        if (label.length > lengthToCut) return setFullText(!fullText);
    };

    const createLabelMarkup = () => {
        let more;
        let currLabel;

        if (!fullText && label.length > lengthToCut) {
            currLabel = label.slice(0, lengthToCut);
            more = `<span>(${translation('account_registerForm_showMore')})</span>`;
        } else if (label.length <= lengthToCut) {
            currLabel = label;
            more = '';
        } else {
            currLabel = label;
            more = `<span>(${translation('account_registerForm_showLess')})</span>`;
        }
        return { __html: `<span>${currLabel}</span>${more}` }
    };

    const toggleCheckbox = (event) => {
        if (!event.target.checked) {
            const { dispatch } = store;
            dispatch(change('registerWizardForm', 'selectAllFields_step1', false));
        }
        input.onChange(event.target.checked);
    };

    return (
        <S.Row>
            <S.CheckboxWrapper className={checkboxWrapperClass} name={input.name}>
                <S.Input 
                    {...input}
                    value={input.value}
                    checked={input.value}
                    onChange={(event) => toggleCheckbox(event)} 
                    type="checkbox" 
                    required={required} 
                />

                <S.FakeChbox className={inputClass}>
                    {input.value && <S.CheckMark />}
                </S.FakeChbox>

                <label
                    dangerouslySetInnerHTML={createLabelMarkup()}
                    onClick={toggleFullText}
                />

                {(touched || submitFailed) && (
                    (error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>)
                )}
            </S.CheckboxWrapper>
        </S.Row>
    );
};

const additionalInfoMarkup = (text) => {
    const [fullText, setFullText] = useState(false);

    const toggleFullText = () => {
        if (text.length > 100) return setFullText(!fullText);
    };

    const createLabelMarkup = () => {
        let more;
        let currLabel;
        if (!fullText && text.length > 100) {
            currLabel = text.slice(0, 100);
            more = `<span>${translation('account_registerForm_showMore')}</span>`;
        } else if (text.length <= 100) {
            currLabel = text;
            more = '';
        } else {
            currLabel = text;
            more = `<span>${translation('account_registerForm_showLess')}</span>`;
        }
        return { __html: `<span>${currLabel}</span>${more}` };
    };

    return (
        <S.AdditionalInfoGrey 
            dangerouslySetInnerHTML={createLabelMarkup()} 
            onClick={toggleFullText} 
        />
    )
};

const normalizeMobile = (value) => value && value.replace(/[\s\-]/g, '');

const normalizePassword = (value) => value && value.replace(/[\s]/g, '');

const handleSelectAllFields = (event, newValue, prevValue) => {
    const { dispatch } = store;
    if (newValue !== prevValue) {
        dispatch(change('registerWizardForm', 'marketingConsent', true));
        dispatch(change('registerWizardForm', 'rodoClause', true));
        dispatch(change('registerWizardForm', 'smsConsent', true));
        dispatch(change('registerWizardForm', 'emailConsent', true));
    } else {
        dispatch(change('registerWizardForm', 'marketingConsent', false));
        dispatch(change('registerWizardForm', 'rodoClause', false));
        dispatch(change('registerWizardForm', 'smsConsent', false));
        dispatch(change('registerWizardForm', 'emailConsent', false));
    }
};

const RegisterWizardFormStep1 = ({ 
    handleSubmit, 
    submitting, 
    openTooltip, 
    synchronousErrors, 
    checkErrorsForDataLayer, 
    formValues 
}) => {
    useEffect(() => {
        pushCustomEventToGoogleAnalytics({ 'event': 'register-1step' }); 
    }, []);

    useEffect(() => {
        const { dispatch } = store;
        if (formValues?.marketingConsent && !formValues?.emailConsent && !formValues?.smsConsent) {
            dispatch(change('registerWizardForm', 'smsConsent', true));
            dispatch(change('registerWizardForm', 'emailConsent', true));
        } else if (!formValues?.marketingConsent) {
            dispatch(change('registerWizardForm', 'smsConsent', false));
            dispatch(change('registerWizardForm', 'emailConsent', false));
        }
    }, [formValues?.marketingConsent]);

    useEffect(() => {
        const { dispatch } = store;
        if (formValues?.emailConsent || formValues?.smsConsent ) {
            dispatch(change('registerWizardForm', 'marketingConsent', true));
        } else if (!formValues?.emailConsent && !formValues?.smsConsent) {
            dispatch(change('registerWizardForm', 'marketingConsent', false));
        }
    }, [formValues?.smsConsent, formValues?.emailConsent]);

    return (
        <S.RegisterWizardFormStep1 
            className="register-step1" 
            onSubmit={handleSubmit} 
            autocomplete="off" 
            noValidate
        >
            <Field
                name="email"
                type="text"
                required
                component={renderField}
                label={translation('account_registerForm_email')}
                focusLabel={translation('account_registerForm_emailFocus')}
                openTooltip={openTooltip}
            />

            <Field
                name="password"
                type="password"
                required
                component={renderField}
                label={translation('account_registerForm_password')}
                focusLabel={translation('account_registerForm_passwordFocus')}
                normalize={normalizePassword}
            />
            <Field
                name="mobile"
                type="text"
                required
                component={renderField}
                label={translation('account_registerForm_phoneNumber')}
                focusLabel={translation('account_registerForm_phoneNumberFocus')}
                normalize={normalizeMobile}
                openTooltip={openTooltip}
                validInfo={translation('account_registerForm_phoneNumberValidInfo')}
            />
            <Field
                name="promoCode"
                type="text"
                required={false}
                component={renderField}
                label={translation('account_registerForm_promoCode')}
                focusLabel={translation('account_registerForm_promoCodeFocus')}
                openTooltip={openTooltip}
            />

            <Field
                name="selectAllFields_step1"
                required={false}
                component={renderCheckbox}
                label={translation('account_registerForm_marketing_selectAll')}
                onChange={handleSelectAllFields}
            />

            <Field
                name="rodoClause"
                required
                type="checkbox"
                component={renderCheckbox}
                label={translation('account_registerForm_acceptRules')}
            />

            <Field
                name="marketingConsent"
                required={false}
                type="checkbox"
                component={renderCheckbox}
                label={translation('account_registerForm_marketing_marketingConsent')}
            />

            <Field
                name="smsConsent"
                type="checkbox"
                required={false}
                indentation
                component={renderCheckbox}
                label={translation('account_registerForm_marketing_smsConsent')}
            />

            <Field
                name="emailConsent"
                type="checkbox"
                required={false}
                indentation
                component={renderCheckbox}
                label={translation('account_registerForm_marketing_emailConsent')}
            />

            {additionalInfoMarkup(translation('account_registerForm_rodoClause'))}

            <S.SubmitButton 
                type="submit" 
                className="next" 
                disabled={submitting} 
                onClick={() => checkErrorsForDataLayer(synchronousErrors)}
            >
                {translation('account_registerForm_next')}
            </S.SubmitButton>

            <S.AdditionalInfo>
                {translation('account_registerForm_accountAvaiable')}
                <S.Link to='/login' onClick={() => redirect('/login')}>{translation('account_registerForm_login')}</S.Link>
            </S.AdditionalInfo>
        </S.RegisterWizardFormStep1>
    )
}

let RegisterWizardForm1 = reduxForm({
    form: 'registerWizardForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(RegisterWizardFormStep1);

const mapStateToProps = (state) => {
    return {
        synchronousErrors: getFormSyncErrors('registerWizardForm')(state),
        formValues: getFormValues('registerWizardForm')(state)
    }
}
RegisterWizardForm1 = connect(mapStateToProps, null)(RegisterWizardForm1);

export default RegisterWizardForm1;