import styled from 'styled-components';
const S = {};

S.ProviderContent = styled.div`
    /* display: ${({active}) => {
        return active ? 'block' : 'block';
    }}; */
`;

S.MinatureProviderComponent = styled.div`
    background-color: #333;
    color: #fff;
    margin-bottom: 0.5rem;
    box-shadow: 0 0 3px #000;

    & > div {
        display: flex;
        align-items: center;
        flex-direction: row;

        & > div:first-child {
            padding: 1rem;
            min-width: 5rem;
            max-width: 5rem;

            & > img {
                max-width: 4.5rem;
            }
        }

        & > div:last-child {
            padding: 1.7rem 0;
        }

        &.loader {
            position: unset;
            padding-top: unset;
            
            & > div {
                min-width: unset;
                max-width: unset;
                padding: unset;
                border: 1.1em solid rgb(51, 51, 51);
                border-left: 1.1em solid #f8941e;
            }
        }
    }

    & > form {
        transition-duration: 1s;
        border-top: 1px solid #fba126;
    }
`;

S.Item = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

S.PaymentsList = styled.div`
    display: flex;
    flex-direction: row;
`;


export default S;
