import styled from 'styled-components';
import {Button} from 'global/styles/styles.js';

const S = {};

S.RegisterOptionsContainer = styled.div`
    color: ${({theme: {$registerFormStepChooseMethodColor}}) => $registerFormStepChooseMethodColor};
    height: fit-content;
`;

S.OptionContainer = styled.div`
    border: 1px solid #E9E9E9;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 90%;
    margin-left: 5%;
    -webkit-box-shadow: 0px 9px 100px 14px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0px 9px 100px 14px rgba(0, 0, 0, 0.17);
    box-shadow: 0px 9px 100px 14px rgba(0, 0, 0, 0.17);
    background: white;
`;

S.OptionLabelRow = styled.div`
    padding: 1rem;
    width: 100%;
    display: flex;
    font-size: 19px;
    line-height: 3rem; 
`;

S.BankIcon = styled.div`
    height: 3rem;
    width: 3rem;
    margin-right: 1rem;
`;

S.ContactFormIcon = styled.div`
    height: 3rem;
    width: 3rem;
    margin-right: 1rem;
`;

S.OptionDescription = styled.div`
    width: calc(100% - 2rem);
    padding: 1rem;
`;

S.OptionChooseBtn = styled(Button)`
    background-color: ${({theme: {$registerFormSubmitButtonBgColor}}) => $registerFormSubmitButtonBgColor};
    color: ${({theme: {$registerFormSubmitButtonColor}}) => $registerFormSubmitButtonColor};
    width: 60%;
    text-align: center;
    box-sizing: border-box;
    border-radius: 16px;
    border: none;
    display: block;
    height: 3.2rem;
    line-height: 3.2rem;
    margin: auto;
    margin-bottom: 1rem;
    &:disabled {
        opacity: .3;
    }
`;

export default S;