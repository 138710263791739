import styled from 'styled-components';
const S = {};

S.AccountDeposit = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

S.Row = styled.div`
    text-align: left;
`;

S.Header = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
`;

S.Text = styled.span`
    font-size: 1.2rem;
`;

S.Balance = styled.span`
    font-weight: bold;
    padding-left: 0.5rem;
`;

S.Currency = styled.span`
    font-weight: bold;
    color: #F58634;
    padding-left: 0.5rem;
`;

S.NoActiveProviders = styled.div`
    text-align: center;
    font-size: 1.5rem;
    margin: 2rem;
`;

S.Button = styled.div`
    background-color: #0218bd;
    cursor: pointer;
    height: 3.33rem;
    line-height: 3.33rem;
    width: 8rem;
    border-radius: 2rem;
    font-size: 1.25rem;
    font-weight: normal;
    color: #FFF;
`;

export default S;