import React from 'react';
import S from 'StyledBackHeader.js';
import { useHistory } from 'react-router-dom';
import { translation } from 'utilsHelper.js';

const BackHeader = () => {
    let history = useHistory();
    return ( 
        <S.BackHeaderWrapper>
            <S.BackButton onClick={() => history.goBack()}><S.Image as="i" dangerouslySetInnerHTML={{__html: arrowBack}}/>
            </S.BackButton>
            <S.Title>{translation('account_sessionHistory')}</S.Title>
        </S.BackHeaderWrapper>
     );
}
 
export default BackHeader;