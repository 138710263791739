import styled, { css } from 'styled-components';
import { Button, Input, Form } from 'global/styles/styles.js';
import { rem } from 'polished';
import StyledLoader from 'StyledLoader.js';

const S = {};

const baseFont = `12px`;
const imagePath = ENV == 'DEV' ? '/' : process.env.MOBILE_URL;

const TooltipButtonColor = ({ theme: { $registerTooltipButtonColor } }) => `${$registerTooltipButtonColor}`;
const FormBackground = ({ theme: { $registerFormBackgroundColor } }) => $registerFormBackgroundColor;
const InputBorderColor = ({ theme: { $registerFormInputBorderColor } }) => `${$registerFormInputBorderColor}`;
const InputErrorColor = ({ theme: { $registerFormErrorColor } }) => `${$registerFormErrorColor}`;
const InputTextColor = ({ theme: { $registerFormInputColor } }) => `${$registerFormInputColor}`;
const InputBackground = ({ theme: { $registerFormInputBackgroundColor } }) => `${$registerFormInputBackgroundColor}`;
const InputPlaceholderColor = ({ theme: { $registerFormTextColor } }) => `${$registerFormTextColor}`;
const InputLabelColor = ({ theme: { $registerFormInputLabelColor } }) => `${$registerFormInputLabelColor}`;
const InputLabelCheckDataColor = ({ theme: { $registerFormInputLabelCheckDataColor } }) => `${$registerFormInputLabelCheckDataColor}`;
const ModalBorderColor = ({ theme: { $registerFormModalBorderColor } }) => `${$registerFormModalBorderColor}`;
const InputBorderCheckDataColor = ({ theme: { $registerInputBorderCheckDataColor } }) => `${$registerInputBorderCheckDataColor}`;
const InputBorderDisabledColor = ({ theme: { $registerInputDisabledBorderDataColor } }) => `${$registerInputDisabledBorderDataColor}`;
const TooltipRegisterByMyIdBorderColor = ({ theme: { $registerTooltiopByMyIdBorderColor } }) => `${$registerTooltiopByMyIdBorderColor}`;

const FontFamilyBarlow = ({ theme: { $fontFamily } }) => `${$fontFamily}`;

const LabelMixin = css`
    margin-top: -1.2rem;
    font-size: 0.625rem;
    color: ${InputLabelColor};
    width: auto;
    /* min-width: 0; */
    padding-right: 0.5rem;
    & .bottomLabel {
        display: none;
    }
    & .topLabel {
        display: block;
    }
`;

const LabelCheckDataMixin = css`
    margin-top: -1.2rem;
    font-size: 0.625rem;
    color: ${InputLabelCheckDataColor};
    width: auto;
    padding-right: 0.5rem;
    & .topLabel {
        display: block;
    }
`;

S.SubmitButton = styled.button`
    box-sizing: border-box;
    width: 100%;
    border: none;
    border-radius: 1.25rem;
    height: 2.5rem;
    text-align: center;
    font-size: 1.5rem;
    color: ${FormBackground};
    background-color: ${InputBorderColor};
    &:disabled {
        background: ${InputPlaceholderColor};
        color: ${FormBackground};
    }
`;

S.ResendVerificationCodeButton = styled(S.SubmitButton)`
    margin-bottom: 1.5rem;
    font-size: 0.8rem;
    font-weight: 600;
`;

S.RegisterWizardForm = styled.div`
    background-color: ${FormBackground};
    height: 100%;
    margin-top: 1.19rem;
`;



S.ConfirmationModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 3rem;
    border: 0.13rem solid ${ModalBorderColor};
    border-radius: 0.5rem;
`;

S.ConfirmationMsg = styled.div`
    width: 100%;
    margin: 3rem 0;
    text-align: center;
    font-size: 0.88rem;
`;

S.ConfirmationCloseBtn = styled(S.SubmitButton)`
    width: 60%;
    height: 3.13rem;
    text-transform: uppercase;
    font-weight: 600;
`;

S.ConfirmationIcon = styled.div`
    width: 65px;
    height: 65px;
`;

S.ConfirmationTitle = styled.div`
    width: 100%;
    margin-bottom: 2.69rem;
    text-align: center;
    font-size: 2.25rem;
    color: ${InputBorderColor};
`;

S.ErrorModal = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    min-width: 0;
    padding: 1.88rem 0.88rem;
    gap: 3rem;
`;

S.ErrorCloseBtn = styled.div`
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
`;

S.ErrorTitle = styled.div`
    font-size: 2.25rem;
    color: ${InputBorderColor};
    font-weight: 700;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-self: center;
    text-align: center;
`;

S.ErrorMessage = styled.div`
    font-size: 1.25rem;
    color: ${InputTextColor};
    font-weight: 500;
`;

S.ErrorConfirmBtn = styled.div`
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 9.56rem;
    height: 2.31rem;
    border-radius: 0.5rem;
    font-size: 1.06rem;
    font-weight: 700;
    background-color: ${InputBorderColor};
    color: ${FormBackground};
`;

S.TooltipModal = styled(S.ConfirmationModal)`
    padding: 0;
    &.registerByMyId {
        border: 0.1rem solid ${TooltipRegisterByMyIdBorderColor}
    }
`;

S.TooltipModalMsg = styled.div`
    padding: 1.4rem 1rem 0;
    font-weight: normal;
    &.registerByMyId {
        padding: 0 1rem 1.4rem;
    }
`;

S.TooltipModalCloseBtn = styled(Button)`
    display: block;
    width: 30%;
    height: 2.625rem;
    padding: 0;
    margin: 0 0.5rem 0 auto;
    background-color: transparent;
    color: ${TooltipButtonColor};
    font-weight: 700;
    font-size: 1.06rem;
    text-transform: uppercase;
`;



S.RegisterWizardFormStep1 = styled(Form)` 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    background-color: ${FormBackground};
    padding: 1.19rem 1.19rem 1.3rem;
`;

S.RegisterWizardFormStep2 = styled(S.RegisterWizardFormStep1)``;

S.Row = styled.div`
    width: 100%;
    &.left {
        width: 45%;
        margin-right: 5%;
    }
    &.right {
        width: 45%;
        margin-left: 5%;
    }
    &.hidden-field {
        visibility: hidden;
        height: 0px;
    }
`;
S.PasswordToggler = styled.div`
    position: absolute;
    right: 5%;
    top: 0.75rem;
    height: 1.56rem;
    width: 1.56rem;
    font-size: 1.25rem;
    & + input + label {
        width: calc(100% - 4.3rem);
    }
`;

S.PasswordTogglerIcon = styled.div``;

S.TooltipToggler = styled.button`
    position: absolute;
    right: 5%;
    top: 0.75rem;
    height: 1.56rem;
    width: 1.56rem;
    font-size: 1.25rem;
    border:none;
    background-color:transparent;
    padding:0;
    z-index:1;
    & + input + label {
        width: calc(100% - 4.3rem);
    }`;
S.TooltipTogglerIcon = styled(S.PasswordTogglerIcon)``;

S.TooltipTogglerRegisterByMyId = styled.div`
    margin-top: 0.5rem;
    margin-left: 0.7rem;
    height: 1.56rem;
    width: 1.56rem;
    font-size: 1.25rem;
    & + input + label {
        width: calc(100% - 4.3rem);
    }
`;

S.Label = styled.label`
    pointer-events: none;
    color: ${InputPlaceholderColor};
    text-align: left;
    position: absolute;
    left: 0.81rem;
    top: 0.8rem;
    width: calc(100% - 1.53rem);
    /* width: auto; */
    /* min-width: 75%; */
    font-size: 1.23rem;
    padding: 0 0 0 0.5rem;
    transition: .2s;
    background: ${InputBackground};
`;

S.TextToggler = styled.span`
    align-self: flex-end;
`;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 5;

    & ${StyledLoader.LoaderWrapper}{
    }
`;
S.InputWrapper = styled.div`
    position: relative;
    padding: 0 0 1.75rem;
    width: 100%;
    margin: 0 auto;
    &.with-select {
        ${S.Label}{
            ${LabelMixin}
        }
    }
    & .prefix-mobile {
        position: absolute;
        top: 0.8rem;
        left: 1.33rem;
        font-size: 1.25rem;
    }
`;

S.CheckboxWrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 1.5rem 0 ;

    &.indentation {
        margin-left: 2rem;
    }

    input {
        position: absolute !important;
        padding: 0 !important;
        margin: 0 !important;
        border: 0 !important;
        overflow: hidden;
        z-index: 0;
        left: 0;
        top: 0;
        height: 2rem;
        width: 2rem;
        cursor: pointer;

        &:checked {
            & + div {
                border-color: ${InputBorderColor};
            }
        }
    }
    &[name="selectAllFields_step1"],
    &[name="selectAllFields_step2"] {
        border-bottom: 2px solid ${InputPlaceholderColor};
        margin-bottom: 1.5rem;
    }

    label {
        color: ${InputPlaceholderColor};
        font-size: 0.75rem;
        display: inline;
        text-align: left;
        position: relative;
        left: 0px;
        top: 0px;
        flex: 1 1 0%;
        line-height: 1.3;
        align-self: center;
        padding-right:2.5rem;

        a {
            color: ${InputBorderColor};
            font-weight: 700;
            text-decoration: none;
        }

        span {
            :nth-of-type(2) {
                margin-left: 0.5rem;
                color: ${InputBorderColor};
                font-weight: 700;
                cursor: pointer;
            }
        }
    }

    &[name="isBonus"] {
        label {
            font-size: 1rem;
            max-width: 70%;
        }
    }

    ${S.TooltipToggler} {
        top: 0.4rem;
    }
`;



S.Input = styled(Input)`
    border: 0.13rem solid ${InputBorderColor};
    border-radius: 0.74rem;
    background-color: ${InputBackground} !important;
    height: 3.13rem;
    color: ${InputTextColor};
    font-size: 1.25rem;
    box-sizing: border-box;
    width: 100%;
    padding: 0 2rem 0 1.33rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: ${FontFamilyBarlow};
    &[name="mobile"] {
        letter-spacing: 0.075rem; // to separate
    }
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px ${InputBackground} inset !important;
        transition: background-color 5000s ease-in-out 0s;
        background-color: ${InputBackground} !important;
        &:hover,
        &:focus,
        &:active {
            -webkit-box-shadow: 0 0 0px 1000px ${InputBackground} inset !important;
            transition: background-color 5000s ease-in-out 0s;
            background-color: ${InputBackground} !important;
        }
    }

    & + label {
        & .topLabel {
            display: none;
        }
        & .bottomLabel {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &:valid,
    &.is-valid {
        &[value]:not([value=""]) {
            & + label {
                ${LabelMixin};
            }
        }
    }
    &.is-valid-check-data {
        &[value]:not([value=""]) {
            & + label {
                ${LabelCheckDataMixin};
            }
        }
        border: 0.13rem solid ${InputBorderCheckDataColor};
    }

    &.disabled-input {
        &[value]:not([value=""]) {
            & + label {
                ${LabelCheckDataMixin};
            }
        }
        border: 0.13rem solid ${InputBorderDisabledColor};
    }

    &:focus {
        outline: 0;
        & + label {
            ${LabelMixin};
        }
    }

    &.has-error {
        border: 0.13rem solid ${InputErrorColor};
        &[value]:not([value=""]) {
            & + label {
                ${LabelMixin};
            }
        }
    }
    &.has-prefix-mobile {
        padding: 0 2rem 0 3.33rem;
    }
`;

S.PepConsentContainer = styled.div`
    display: flex;
    width: 100%;
    color: ${InputLabelCheckDataColor};
    margin-bottom: 2rem;
    position: relative;
`;

S.PepLabelContainer = styled.div`
    width: 60%;
    display: flex;
`;

S.PepLabel = styled.div`
    font-size: 14px;
    line-height: 2.5rem;
`;

S.PepLabelQuestionMark = styled.div`
    width: 1rem;
    height: 1rem;
    margin-top: 0.8rem;
    margin-left: 0.8rem;
    background-color: red;
`;

S.PepRadioButtonContainer = styled.div`
    width: 20%;
    display: flex;
    position: relative;
`;

S.PepInputRadioButton = styled(Input)`
    width: 2.5rem!important;
    height: 2.5rem!important;
    display: inline-block;
    position: absolute;
    z-index: 0;
    margin: 0px;
`;

S.PepInputRadioButtonLabel = styled.div`
    font-size: 16px;
    display: inline-block;
    line-height: 2.5rem;
    margin-left: 0.3rem;
`;

S.PepErrorMessageContainer = styled.div`
    position: absolute;
    bottom: -1rem;
    left: -2rem;
`;

S.AdditionalInfoRegByMyId = styled.div`
    text-align: center;
    font-size: 0.81rem;
    color: ${InputTextColor};
    line-height: 1.7;
    margin-top: 1rem;
    width: 100%;
`;

S.LinkHelp = styled.span`
    color: ${InputBorderColor};
    font-size: 0.81rem;
    margin: auto;
    font-weight: 700;
    cursor: pointer;
`;

S.FakePepRadioButtonActive = styled.div`
    position: relative;
    z-index: 3;
    width: 2.5rem;
    height: 2.5rem;
    pointer-events: none;
`;

S.FakeChbox = styled.div`
    position: relative;
    z-index: 3;
    flex-shrink: 0;
    width: 1.94rem;
    height: 1.94rem;
    border: 0.13rem solid ${InputPlaceholderColor};
    border-radius: 0.25rem;
    margin-right:  0.63rem;
    background-color: ${FormBackground};
    pointer-events: none;
    cursor: pointer;
    &.has-error {
        border: 0.13rem solid ${InputErrorColor};
    }
    & ~ div {
        padding: 0 2.88rem;
    }
`;

S.CheckMark = styled.span`
    &:after {
        position: absolute;
        top: 0.19rem;
        left: 0.19rem;
        display:block;
        content: '\f00c';
        font-family: FontAwesome;
        color: ${InputBorderColor};
        font-size: 1.5rem;
    }
`;



S.PasswordStrengthChecker = styled.div`
    display: flex;
    align-items: center;
    justify-items: flex-start;
    flex-wrap: wrap;
    padding: 0 1.31rem;
    margin-top: 0.19rem;
`;

S.PasswordField = styled.div`
    display: block;
    color: ${InputBorderColor};
    font-size: 0.75rem;
    margin: 0 0.25rem 0 0;
    line-height: 1.1rem;
    &.no-touched {
        color: #787d83;
    }
    &.has-error {
        color: ${InputErrorColor};
    }
    &:after {
        content: '\\2022';
        font-size: 1rem;
        margin-left: 0.25rem;
        color: ${InputTextColor};
    }
    &:last-child {
        &:after {
            display: none;
        }
    }
`;

S.Info = styled.div`
    flex: 1 100%;
    padding: 0 1.31rem;
    margin-top: 0.19rem;
    font-size: 0.89rem;
    text-align: center;
`;

S.Error = styled(S.Info)`
    text-align: left;
    color: ${InputErrorColor};
`;

S.ValidInfo = styled(S.Info)`
    text-align: left;
    color: ${InputLabelColor};
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: #CE0006;
    font-size: 1rem;
    padding: .83rem;
    background-color: #fba59f;
    margin-bottom: .5rem;
    text-align: center;
`;

S.AdditionalInfo = styled.div`
    text-align: center;
    font-size: 0.81rem;
    color: ${InputTextColor};
    line-height: 1.7;
    margin: 1rem 0;
`;

S.AdditionalInfoGrey = styled(S.AdditionalInfo)`
    color: ${InputPlaceholderColor};
    span {
        :nth-of-type(2) {
            margin-left: 0.5rem;
            color: ${InputBorderColor};
            font-weight: 700;
            cursor: pointer;
        }
    }
`;

S.Link = styled.span`
    color: ${InputBorderColor};
    font-size: 0.81rem;
    margin-left: 0.5rem;
    font-weight: 700;
    cursor: pointer;
`;

S.Body = styled.div`
    padding:  ${rem('30px', baseFont)};
`;

S.Header = styled.div`
    position: relative;
    padding: 0 1.06rem;
`;

S.RegisterWizardFormTitle = styled.h2`
    padding: 0 1.56rem;
    margin: 0 auto;
    color: ${InputBorderColor};
    font-size: 1.63rem;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
`;

S.HeaderButton = styled(Button)`
    position: absolute;
    top: 0;
    display:flex;
    background-color: transparent;
    align-items: center;
    padding: 0;
    &.closeButton {
        right: 1.06rem;
    }
    &.backButton {
        left: 1.06rem;
    }
`;

S.HeaderButtonIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.56rem;
    height: 1.56rem;
    cursor: pointer;
    & svg{
        width: 100%;
        & path{
            fill: ${InputTextColor} !important;
        }
    }
`;

S.ErrorCloseIcon = styled(S.HeaderButtonIcon)``;

S.Select = styled.div`
    align-items: stretch;
    display: block;
    & .react-select__menu{
        margin-top: 0;
        z-index: 5;
        & .react-select__menu-list{
            padding: 0;
            & > div{
            }
            .react-select__option {
                color: rgba(0, 0, 0, 0.7);
                font-size: 1.16rem;
            }
            &--is-multi {
                .react-select__option {
                    color: #777D85;
                        &--is-focused {
                        background-color: #E8E8E8;
                    }
                    &--is-selected {
                        background-color: inherit;
                        font-weight: 600;
                        color: #262626;
                        &.react-select__option {
                            &--is-focused {
                                background-color: #E8E8E8;
                            }
                        }
                    }
                }
            }
        }
    }

    & .react-select__control{
        box-shadow: none;
        min-height: 3.13rem;
        border: 0.13rem solid ${InputBorderColor};
        border-radius: 0.74rem;
        align-items: flex-start;
        background-color: ${InputBackground};
        & .react-select__dropdown-indicator{
            width: 0;
            height: 0;
            padding: 0;
            margin-right: 1.25rem;
            border-left: 0.63rem solid transparent;
            border-right: 0.63rem solid transparent;
            border-top: 0.63rem solid ${InputBorderColor};
            svg {
                display: none;
            }
        }

        & .react-select__clear-indicator{
            display: none;
        }

        & .react-select__indicator-separator{
            display: none;
        }

        & .react-select__value-container{
            height: 2.9rem;
            padding: 0;
            margin-left: 1.25rem;
            margin-right: 2rem;
            & .react-select__single-value{
                color: ${InputTextColor};
                font-size: 1.25rem;
            }
            &--is-multi {
                flex-wrap: nowrap;
                text-overflow: ellipsis;
                max-width: 90%;
                white-space: nowrap;
                overflow: hidden;
                display: initial;
            }
        }
        &:hover {
            border: 2px solid ${InputBorderColor};
        }
    }
`;

// S.SelectDownshift = styled.div`
//     .select-wrapper {
//         display: flex;
//         width: 100%;
//         position: relative
//     }
//     .select-button {
//         position: absolute;
//         background: transparent;
//         width: 100%;
//         border: none !important;
//         outline: none;
//         height: 100%;

//         &:after {
//             content: "";
//             position: absolute;
//             width: 0;
//             right: 15px;
//             top: 24px;
//             height: 0;
//             border-style: solid;
//             border-width: 10px 10px 0 10px;
//             border-color: ${InputBorderColor} transparent transparent transparent;
//         }
//     }
//     .select-list {
//         max-height: 300px;
//         overflow: auto;
//         margin: 0;
//         position: absolute;
//         z-index: 30;
//         width: 100%;
//         border: 1px solid ${InputPlaceholderColor};
//         border-radius: 10px;
//         font-size: 1.3rem;
//         background: ${InputBackground};
//         &:empty {
//             border: none;
//         }

//         .select-item {
//             padding: 0.5rem;
//         }
//     }
// `;

export default S;