import styled from 'styled-components';
import StyledLoader from 'StyledLoader.js';
import {Form, Input, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;
const FontFamily = ({theme:{$fontFamily}}) => `${$fontFamily}`;

const InputBorderColor = ({ theme: { $accountLimitsInputBorderColor } }) => `${$accountLimitsInputBorderColor}`;
const InputBackground = ({ theme: { $accountLimitsInputBackgroundColor } }) => `${$accountLimitsInputBackgroundColor}`;
const InputErrorColor = ({ theme: { $accountLimitsErrorColor } }) => `${$accountLimitsErrorColor}`;
const InputTextColor = ({ theme: { $accountLimitsInputColor } }) => `${$accountLimitsInputColor}`;
const InputLabelColor = ({ theme: { $accountLimitsInputLabelColor } }) => `${$accountLimitsInputLabelColor}`;
const InputPlaceholderColor = ({ theme: { $accountLimitsTextColor } }) => `${$accountLimitsTextColor}`;
const FormBackground = ({ theme: { $accountLimitsBackgroundColor } }) => $accountLimitsBackgroundColor;

const accountLimitsHeaderTextColor = ({ theme: { $accountLimitsHeaderTextColor } }) => `${$accountLimitsHeaderTextColor}`;
const accountLimitsOptionBackgroundColor = ({ theme: { $accountLimitsOptionBackgroundColor } }) => `${$accountLimitsOptionBackgroundColor}`;
const accountLimitsOptionBackgroundActiveColor = ({ theme: { $accountLimitsOptionBackgroundActiveColor } }) => `${$accountLimitsOptionBackgroundActiveColor}`;
const accountLimitsInfoBackgroundColor = ({ theme: { $accountLimitsInfoBackgroundColor } }) => `${$accountLimitsInfoBackgroundColor}`;
const accountLimitsErrorBackgroundColor = ({ theme: { $accountLimitsErrorBackgroundColor } }) => `${$accountLimitsErrorBackgroundColor}`;

const baseFont = `14px`;

const S = {};

S.OptionMarkerWrapper = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
`;

S.LimitOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  flex: 1;

  &.active{
    color: ${accountLimitsOptionBackgroundActiveColor};

    & .box{
      background-color: ${accountLimitsOptionBackgroundActiveColor};
    }
  }
`;

S.LimitBox = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 5px;
  background-color: ${accountLimitsOptionBackgroundColor};
`;

S.LimitName = styled.span`
  font-size: ${rem('14px', baseFont)};
  margin-top: .5rem;
  font-weight: ${FwSemibold};
`;

S.LimitsInfo = styled.div`
  font-size: ${rem('12px', baseFont)};
  padding: 1rem;
  background-color: ${accountLimitsInfoBackgroundColor};
  border-radius: 5px;
  margin-top: 1rem;
`;

S.TooltipToggler = styled.div`
  position: absolute;
  right: 5%;
  top: 0.6rem;
  height: 1.56rem;
  width: 1.56rem;
  font-size: 1.25rem;
`;

S.TooltipTogglerIcon = styled.div``;

S.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  position: relative;
`;

S.Title = styled.h2`
   margin: 0;
   color: ${accountLimitsHeaderTextColor};
    font-size: ${rem('18px', baseFont)};
    padding: 0;
    font-weight: ${FwSemibold};
    text-align: center;
`;

S.ResponsibleGamingForm = styled(Form)`
  font-family: ${FontFamily};
  position: relative;
  background-color: #fff;
  width: 85%;
  margin: 0 auto;
`;

S.Input = styled(Input)`
    border: 2px solid ${InputBorderColor};
    border-radius: 5px;
    background-color: ${InputBackground} !important;
    height: 3rem;
    color: ${InputTextColor};
    font-size: ${rem('16px', baseFont)};
    box-sizing: border-box;
    width: 100%;
    padding: 0px 1.5rem;

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px ${InputBackground} inset !important;
        transition: background-color 5000s ease-in-out 0s;
        background-color: ${InputBackground} !important;
        &:hover,
        &:focus,
        &:active {
            -webkit-box-shadow: 0 0 0px 1000px ${InputBackground} inset !important;
            transition: background-color 5000s ease-in-out 0s;
            background-color: ${InputBackground} !important;
        }
    }

    &.has-error {
        border: 2px solid ${InputErrorColor};
    }
`;

S.Label = styled.label`
    pointer-events: none;
    color: ${InputLabelColor};
    text-align: left;
    position: absolute;
    left: 1.1rem;
    top: 0.7rem;
    width: auto;
    font-size: ${rem('10px', baseFont)};
    font-weight: 400;
    padding: 0 0.5rem;
    transition: .2s;
    background-color: ${InputBackground};
    font-weight: ${FwSemibold};
    margin-top: -1.3rem;
    min-width: 0;
`;

S.Row = styled.div`
    width: 100%;
`;

S.SubmitBtn = styled(Button)`
    box-sizing: border-box;
    width: 100%;
    border: none;
    border-radius: 1.50rem;
    height: 3rem;
    text-align: center;
    font-size: ${rem('18px', baseFont)};
    color: ${FormBackground};
    background-color: ${InputBorderColor};
    margin-bottom: 4.75rem;
    &:disabled {
        background: ${InputPlaceholderColor};
        color: ${InputBorderColor};
    }
`;

S.LoaderWrapper = styled.div`
    width: ${rem('360px', baseFont)};
`;


S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 5;
`;

S.Error = styled.div`
  flex: 1 100%;
  margin-top: 0.19rem;
  text-align: left;
  font-size: ${rem('12px', baseFont)};;
  font-weight: 700;
  color: ${InputErrorColor};
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    font-size: 1rem;
    text-align: left;
    padding: 1rem;
    background-color: ${accountLimitsErrorBackgroundColor};
    margin-bottom: ${rem('20px', baseFont)};
    display: flex;
    align-items: center;

    & .ion{
      font-size: 1.5rem;
      margin-right: 1rem;
      color: ${InputErrorColor};
    }

    & .errorWrapper{
      display: flex;
      flex-direction: column;
    }
`;

S.InputWrapper = styled.div`
    position: relative;
    padding: 0 0 1.75rem;
    width: 100%;
    margin: 0 auto;

    .__react_component_tooltip {
        &.type-light {
            &.place-top {
                max-width: 42%;
                &:after {
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    bottom: -6px;
                    left: 50%;
                    margin-left: -8px;

                }
                &:before {
                    border-left: 9px solid transparent;
                    border-right: 9px solid transparent;
                    margin-left: -9px;
                    border-top-color: rgba(26, 103, 52, 0.5);
                    border-top-style: solid;
                    border-top-width: 6px;
                }
            }
        }

        &.show {
            opacity: 1;
            box-shadow: 0px 0px 0px 2px rgba(26, 103, 52, 0.5);
        }
    }
`;
S.Body = styled.div``;

S.BodyInner = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
`;

S.Footer = styled.div`
    display: flex;
`;

export default S;