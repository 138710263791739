import validation from 'sb-validate-js/validate.js';
import { translation } from 'utilsHelper.js';
import {substractYears} from 'datesHelper.js'
import {isPeselAdult, isPeselValid} from 'validateHelper.js';
import _range from 'lodash/range';
import _repeat from 'lodash/repeat';
import _reduce from 'lodash/reduce';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
// import { validate } from 'autonumeric';

validation.validators.checkPolishPostalCodePattern = function (value, options, key, global) {
    const { nationality } = global;
    const { message } = options;
    if (value && String(value).replace(/\s/g, '').length) {
        if (nationality == 'PL') {
            return !/^[0-9]{2}-[0-9]{3}$/.test(value) ? message() : null;
        } else {
            return !/^[a-zA-Z0-9\-\s\/]{2,8}$/.test(value) ? message() : null;
        }
    }
    return null;
};

validation.validators.isValidDate = function (value, { message, parseSign = '-' }, key, global) {
    let day, month, year;
    if (typeof value == 'string') {
        const dateParts = value.split(parseSign).map(Number);
        if (parseSign == '/') {
            day = dateParts[0];
            month = (dateParts[1] > 0 ? dateParts[1] - 1 : 0);
            year = dateParts[2];
        } else {
            day = dateParts[2];
            month = (dateParts[1] > 0 ? dateParts[1] - 1 : 0);
            year = dateParts[0];
        }
        const date = new Date(year, month, day);
        value = date;
    } else {
        let { dayOfBirth, monthOfBirth, yearOfBirth } = global;
        day = (+dayOfBirth);
        month = (+monthOfBirth);
        year = (+yearOfBirth);
    }

    const isValid = (value instanceof Date) && ((value.getFullYear() == year) && (value.getMonth() == month) && (value.getDate() == day));
    return !isValid ? message() : null;
};

validation.validators.isAdult = function (value, { message, parseSign = '-', latest }) {
    if (typeof value == 'string') {
        const dateParts = value.split(parseSign).map(Number);
        const date = parseSign == '/' ? new Date(dateParts[2], (dateParts[1] > 0 ? dateParts[1] - 1 : 0), dateParts[0]) : new Date(dateParts[0], (dateParts[1] > 0 ? dateParts[1] - 1 : 0), dateParts[2]);
        value = date;
    }
    const adult = value <= latest;
    return !adult ? message() : null;
};

validation.validators.checkPostalCodePattern = function (value, options, key, global) {
    const {message} = options;
    if(value && String(value).replace(/\s/g, '').length){
      return !/^[a-zA-Z0-9\-\s\/]{2,8}$/.test(value) ? message() : null;  
    }
    return null;
};

validation.validators.futureDate = function (value, { message, parseSign = '-' }) {
    if (typeof value == 'string') {
        const dateParts = value.split(parseSign).map(Number);
        const date = parseSign == '/' ? new Date(dateParts[2], (dateParts[1] > 0 ? dateParts[1] - 1 : 0), dateParts[0]) : new Date(dateParts[0], (dateParts[1] > 0 ? dateParts[1] - 1 : 0), dateParts[2]);
        value = date;
    }
    const futureDate = value > new Date;
    return futureDate ? message() : null;
};

validation.validators.isPeselAdult = function (value, {message}) {
    const size = value && _size(value);
    if (size == 11) {
        const adult = isPeselAdult(value);
        return !adult ? message() : null;
    }
    return null;
};

validation.validators.isPeselValid = function (value, {message}) {
    const size = value && _size(value);
    if (size == 11) {
        const valid = isPeselValid(value);
        return !valid ? message() : null;
    }
    return null;
};

let prohibitedPhoneNumbers = _reduce(_range(1, 10), (initialArray, nextElem) => {
    const number = _repeat(nextElem, 9);
    return [...initialArray, number];
}, []);
prohibitedPhoneNumbers = [...prohibitedPhoneNumbers, ...['123456789', '987654321', '123123123']];


validation.validators.isPasswordValidCustom = function (value, {description}, key, attributes) {
    const errorObject = {};

    // lower case
    const lowerCase = /(?=.*[a-zżźćńółęąś])/;
    const lowerCaseMessage = translation('account_registerForm_passwordLowerCase');

    // upper case
    const upperCase = /(?=.*[A-ZŻŹĆĄŚĘŁÓŃ])/;
    const upperCaseMessage = translation('account_registerForm_passwordUpperCase');

    // number
    const digit = /(?=.*\d)/;
    const digitMessage = translation('account_registerForm_passwordNumberCase');

    // length
    const minLength = 8;
    const lengthCaseMessage = translation('account_registerForm_passwordTooShort');

    if (!value || !lowerCase.test(value)) {
        errorObject.lowerCase = lowerCaseMessage;
    }
    if (!value || !upperCase.test(value)) {
        errorObject.upperCase = upperCaseMessage;
    }
    if (!value || !digit.test(value)) {
        errorObject.digit = digitMessage;
    }
    if (!value || value.length < minLength)  {
        errorObject.length = lengthCaseMessage;
    }
    return !_isEmpty(errorObject) ? errorObject : null;

};

validation.validators.limitIsDailyHigherThanMonthly = function (value, {message, typeToCompare, sign}, key, attributes) {
  const compareValue = attributes[`limitType${typeToCompare}`];
  if(sign == "higher"){
    return Number(compareValue) < Number(value) ? message() : null;
  } else if(sign == "lower"){
    return Number(compareValue) > Number(value) ? message() : null;
  }
};

export default {
    REGISTER_STEP1: {
        email: {
            presence: {
                message: () => translation('account_registerForm_emailEmpty'),
            },
            email: {
                message: () => translation('account_registerForm_wrongEmailPattern'),
            },
            length: {
                minimum: 5,
                maximum: 120,
                tooShort: () => translation('account_registerForm_emailTooShort'),
                tooLong: () => translation('account_registerForm_emailTooLong'),
            }
        },
        password: {
            isPasswordValidCustom: {
                description: 'customPasswordValidationMethod'
            },
        },
        mobile: {
            presence: {
                message: () => translation('account_registerForm_phoneEmpty'),
            },
            format: {
                pattern: /^[1-9][0-9]{8}$/,
                message: () => translation('account_registerForm_wrongPhoneNumberPattern'),
            },
            length: {
                minimum: 9,
                maximum: 9,
                tooShort: () => translation('account_registerForm_phoneTooShort'),
                tooLong: () => translation('account_registerForm_phoneTooLong'),
            },
            exclusion: {
                within: prohibitedPhoneNumbers,
                message: () => translation('account_registerForm_prohibitedPhoneNumber'),
            },
            numericality: {
                onlyInteger: true,
                notInteger: () => translation('account_registerForm_onlyInteger'),
            }
        },
        rodoClause: {
            inclusion: {
                within: [true],
                message: () => translation('account_registerForm_adult'),
            },
            presence: {
                message: () => translation('account_registerForm_adult'),
            },
        }
    },
    REGISTER_STEP2: {
        firstName: {
            presence: {
                message: () => translation('account_registerForm_firstNameEmpty'),
            },
            length: {
                minimum: 3,
                tooShort: () => translation('account_registerForm_firstNameTooShort'),
            }
        },
        lastName: {
            presence: {
                message: () => translation('account_registerForm_lastNameEmpty'),
            },
            length: {
                minimum: 3,
                tooShort: () => translation('account_registerForm_lastNameTooShort'),
            }
        },
        city: {
            presence: {
                allowEmpty: false,
                message: () => translation('account_registerForm_addressCityEmpty'),
            },
            length: {
                minimum: 3,
                tooShort: () => translation('account_registerForm_cityTooShort'),
            },
        },
        street: {
            presence: {
                allowEmpty: false,
                message: () => translation('account_registerForm_addressEmpty'),
            },
            format: {
                pattern: /[\D\d\s-./][^!"#$;*+<=>@~{|}`]*/,
                message: () => translation('account_editProfileForm_addressWrongPattern'),
            },
            length: {
                minimum: 3,
                tooShort: () => translation('account_editProfileForm_toShort'),
            },
        },
        postalCode: {
            presence: {
                message: () => translation('account_registerForm_postalCodeEmpty'),
            },
            checkPolishPostalCodePattern: {
                message: () => translation('account_registerForm_postalCodeWrongPattern'),
            }
        },
        dateOfBirth: {
            isValidDate: {
                message: () => translation('account_registerForm_dateOfBirthNotValid'),
                parseSign: '/'
            },
            futureDate: {
                parseSign: '/',
                message: () => translation('account_registerForm_futureDate')
            },
            isAdult: {
                latest: substractYears(new Date, 18),
                parseSign: '/',
                message: () => translation('account_registerForm_adult')
            }
        },
        personalIdentifier: {
            isPeselValid: {
                message: () => translation('account_registerForm_peselNotValid')
            },
            isPeselAdult: {
                message: () => translation('account_registerForm_adult')
            },
            presence: {
                message: () => translation('account_registerForm_peselEmpty'),
            },
            length: {
                minimum: 11,
                maximum: 11,
                tooShort: () => translation('account_registerForm_peselTooShort'),
                tooLong: () => translation('account_registerForm_peselTooLong'),
            }
        },
        passportNumber: {
            presence: {
                message: () => translation('account_registerForm_passportNumberEmpty'),
            },
        },
        verificationCode: {
            presence: {
                message: () => translation('account_registerForm_verificationCodeEmpty'),
            },
            format: {
                pattern: /^[0-9]{6}$/,
                message: () => translation('account_registerForm_wrongVerificationCodePattern'),
            },
            length: {
                minimum: 6,
                maximum: 6,
                tooShort: () => translation('account_registerForm_verificationCodeTooShort'),
                tooLong: () => translation('account_registerForm_verificationCodeTooLong'),
            },
            numericality: {
                onlyInteger: true,
                message: () => translation('account_registerForm_verificationCodeIntegerOnly')
            }
        },
    },

    ACCOUNT_LIMITS: {
      limitType1: {
        presence: {
            message: () => translation('accountLimits_valueEmpty'),
            allowEmpty: false
        },
        limitIsDailyHigherThanMonthly: {
          message: () => translation('accountLimits_limitHigher'),
          typeToCompare: 3,
          sign: 'higher'
        }
      },
      limitType3: {
        presence: {
            message: () => translation('accountLimits_valueEmpty'),
            allowEmpty: false
        },
        limitIsDailyHigherThanMonthly: {
          message: () => translation('accountLimits_limitLower'),
          typeToCompare: 1,
          sign: 'lower'
        }
      },
      limitType13: {
        presence: {
            message: () => translation('accountLimits_valueEmpty'),
            allowEmpty: false
        },
        limitIsDailyHigherThanMonthly: {
          message: () => translation('accountLimits_limitHigher'),
          typeToCompare: 15,
          sign: 'higher'
        }
      },
      limitType15: {
        presence: {
            message: () => translation('accountLimits_valueEmpty'),
            allowEmpty: false
        },
        limitIsDailyHigherThanMonthly: {
          message: () => translation('accountLimits_limitLower'),
          typeToCompare: 13,
          sign: 'lower'
        }
      }
    },

    REGISTER_BY_MY_ID_STEP1: {
        firstName: {
            presence: {
                allowEmpty: false,
                message: () => translation('account_registerForm_firstNameEmpty'),
            },
            length: {
                minimum: 3,
                tooShort: () => translation('account_registerForm_firstNameTooShort'),
            }
        },
        lastName: {
            presence: {
                allowEmpty: false,
                message: () => translation('account_registerForm_lastNameEmpty'),
            },
            length: {
                minimum: 3,
                tooShort: () => translation('account_registerForm_lastNameTooShort'),
            }
        },
        dateOfBirth: {
            isValidDate: {
                message: () => translation('account_registerForm_dateOfBirthNotValid'),
                parseSign: '-'
            },
            futureDate: {
                parseSign: '-',
                message: () => translation('account_registerForm_futureDate')
            },
            isAdult: {
                latest: substractYears(new Date, 18),
                parseSign: '-',
                message: () => translation('account_registerForm_adult')
            }
        },
        personalIdentificationNumber: {
            isPeselValid: {
                message: () => translation('account_registerForm_peselNotValid')
            },
            isPeselAdult: {
                message: () => translation('account_registerForm_adult')
            },
            presence: {
                allowEmpty: false,
                message: () => translation('account_registerForm_peselEmpty'),
            },
            length: {
                minimum: 11,
                maximum: 11,
                tooShort: () => translation('account_registerForm_peselTooShort'),
                tooLong: () => translation('account_registerForm_peselTooLong'),
            }
        },

        documentType: {
            presence: {
                allowEmpty: false,
                message: () => translation('account_registerForm_documentTypeEmpty'),
            },
        },

        documentNumber: {
            presence: {
                allowEmpty: false,
                message: () => translation('account_registerForm_documentNumberEmpty'),
            },
        },

        documentExpirationDate: {
            presence: {
                allowEmpty: false,
                message: () => translation('account_registerForm_documentExpirationDateEmpty'),
            },
        },

        countryOfBirth: {
            length: {
                minimum: 2,
                maximum: 2,
                tooShort: () => translation('account_registerForm_countryOfBirthTooShort'),
                tooLong: () => translation('account_registerForm_countryOfBirthTooLong'),
            },
            presence: {
                allowEmpty: false,
                message: () => translation('account_registerForm_countryOfBirthEmpty'),
            },
        },

        nationality: {
            length: {
                minimum: 2,
                maximum: 2,
                tooShort: () => translation('account_registerForm_nationalityTooShort'),
                tooLong: () => translation('account_registerForm_nationalityTooLong'),
            },
            presence: {
                allowEmpty: false,
                message: () => translation('account_registerForm_nationalityEmpty'),
            },
        },

        city: {
            presence: {
                allowEmpty: false,
                message: () => translation('account_registerForm_addressCityEmpty'),
            },
            length: {
                minimum: 3,
                tooShort: () => translation('account_registerForm_cityTooShort'),
            },
        },

        street: {
            presence: {
                allowEmpty: false,
                message: () => translation('account_registerForm_addressEmpty'),
            },
            format: {
                pattern: /[\D\d\s-./][^!"#$;*+<=>@~{|}`]*/,
                message: () => translation('account_editProfileForm_addressWrongPattern'),
            },
            length: {
                minimum: 3,
                tooShort: () => translation('account_editProfileForm_toShort'),
            },
        },

        number: {
            presence: {
                allowEmpty: false,
                message: () => translation('account_registerForm_numberEmpty'),
            },
        },

        postalCode: {
            presence: {
                allowEmpty: false,
                message: () => translation('account_registerForm_postalCodeEmpty'),
            },
            checkPolishPostalCodePattern: {
                message: () => translation('account_registerForm_postalCodeWrongPattern'),
            }
        },

        country: {
            length: {
                minimum: 2,
                maximum: 2,
                tooShort: () => translation('account_registerForm_countryTooShort'),
                tooLong: () => translation('account_registerForm_countryTooLong'),
            },
            presence: {
                allowEmpty: false,
                message: () => translation('account_registerForm_addressCountryEmpty'),
            },
        },

        bankAccountNumber: {
            presence: {
                allowEmpty: false,
                message: () => translation('account_registerForm_bankAccountNumberEmpty'),
            },
        }

    },

    REGISTER_BY_MY_ID_STEP2: {
        email: {
            presence: {
                message: () => translation('account_registerForm_emailEmpty'),
            },
            email: {
                message: () => translation('account_registerForm_wrongEmailPattern'),
            },
            length: {
                minimum: 5,
                maximum: 120,
                tooShort: () => translation('account_registerForm_emailTooShort'),
                tooLong: () => translation('account_registerForm_emailTooLong'),
            }
        },
        password: {
            isPasswordValidCustom: {
                description: 'customPasswordValidationMethod'
            },
        },
        mobile: {
            presence: {
                message: () => translation('account_registerForm_phoneEmpty'),
            },
            format: {
                pattern: /^[1-9][0-9]{8}$/,
                message: () => translation('account_registerForm_wrongPhoneNumberPattern'),
            },
            length: {
                minimum: 9,
                maximum: 9,
                tooShort: () => translation('account_registerForm_phoneTooShort'),
                tooLong: () => translation('account_registerForm_phoneTooLong'),
            },
            exclusion: {
                within: prohibitedPhoneNumbers,
                message: () => translation('account_registerForm_prohibitedPhoneNumber'),
            },
            numericality: {
                onlyInteger: true,
                notInteger: () => translation('account_registerForm_onlyInteger'),
            }
        },
        rodoClause: {
            inclusion: {
                within: [true],
                message: () => translation('account_registerForm_adult'),
            },
            presence: {
                message: () => translation('account_registerForm_adult'),
            },
        }
    }
};

