import React, {useEffect, useRef} from 'react';

export const UsePrevious = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export default UsePrevious;

