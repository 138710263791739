import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { translation } from 'helpers/utilsHelper.js'
import { connect, useDispatch } from 'react-redux'
import { getIntegration } from 'integrationActions.js'
import * as styled from 'StyledNSoft'

const PROVIDER = 'nsoft'
const LUCKYSIX_ID = '1'

const NSoft = ({ isLogged }) => {
  const [gameList, setGameList] = useState([])
  const [selectedGame, setSelectedGame] = useState(undefined)
  const [gameURL, setGameURL] = useState(undefined)
  const dispatch = useDispatch()

  const [isNavOpen, setIsNavOpen] = useState(false)

  // Get games list
  useEffect(() => {
    const getGameList = async () => {
      const json = await dispatch(getIntegration({
        provider: PROVIDER,
        method: 'game_list'
      }))

      const gameList = JSON.parse(json).filter(({ game_id: gameId }) => gameId !== LUCKYSIX_ID)

      setGameList(gameList)
    }

    getGameList()
  }, [dispatch, setGameList])

  // Get first game
  useEffect(() => {
    !!gameList.length && setSelectedGame(gameList[0])
  }, [gameList.length, setSelectedGame])

  // Get iframe URL
  useEffect(() => {
    const getGameURL = async () => {
      const gameURL = selectedGame && await dispatch(getIntegration({
        provider: PROVIDER,
        method: `get_game?gameId=${selectedGame.game_id}`
      }))

      setGameURL(gameURL)
    }

    getGameURL()
  }, [dispatch, selectedGame, setGameURL, isLogged])

  return (
    <styled.Container>
      <styled.Nav>
        <styled.NavBar onClick={() => setIsNavOpen(!isNavOpen)}>
          {!!selectedGame && translation(`nsoft_${selectedGame.name}`)}
          <styled.NavBarToggle isNavOpen={isNavOpen} />
        </styled.NavBar>
        <styled.NavList isNavOpen={isNavOpen}>
        {!!gameList.length && gameList.map(game => (
          <Item
            key={game.game_id}
            game={game}
            selectedGame={selectedGame}
            setSelectedGame={setSelectedGame}
            setIsNavOpen={setIsNavOpen}
          />
        ))}
        </styled.NavList>
      </styled.Nav>
      {gameURL ? (
        <styled.Iframe
          windowHeight={window.innerHeight}
          src={gameURL}
          title={selectedGame.name}
          id='NSoft'
        />
      ) : null}
    </styled.Container>
  )
}

const Item = ({ game, setSelectedGame, selectedGame, setIsNavOpen }) => {
  const onClick = useCallback(() => {
    setSelectedGame(game)
    setIsNavOpen(false)
  }, [setSelectedGame, setIsNavOpen, game])

  const isActive = useMemo(() => selectedGame && game.game_id === selectedGame.game_id, [game, selectedGame])

  return (
    <styled.NavItem>
      <styled.NavButton onClick={onClick} isActive={isActive}>
        {translation(`nsoft_${game.name}`)}
      </styled.NavButton>
    </styled.NavItem>
  )
}

const mapStateToProps = ({ Auth: { isLogged } }) => ({ isLogged })

export default connect(mapStateToProps)(NSoft)
